import PropTypes from "prop-types";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import Logo from "../../components/general/Logo";
import ModalLayout from "../ModalLayout";
import Button from "../../components/UI/Button";
import Dot from "../../components/UI/Dot";
import HeaderDashboardSideBar from "../../components/headerDashboard/HeaderDashboardSideBar";
import HeaderDashboardMenu from "../../components/headerDashboard/HeaderDashboardMenu";

import styles from "../../../scss/components/headerDashboard/HeaderDashboard.module.scss";

import useDataPortal from "hooks/useDataPortal";

const HeaderDashboard = ({ dashboard }) => {
  const router = useRouter();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [url, setUrl] = useState("");

  const dataPortal = useDataPortal();

  const sidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  };

  const createNew = (e) => {
    e.preventDefault();
    router.push("/createPub");
  };

  const backToHome = (e) => {
    e.preventDefault();
    router.push("/");
  };

  useEffect(() => {
    if (url !== router.asPath) {
      setUrl(`${router.asPath}`);
      setOpenSidebar(false);
    }
  }, [router.asPath]);

  return (
    <div className={styles.container}>
      <Wrapper styleWrapper="wrapperHeader">
        <Logo href="/" urlLogo={`/static/images/portals/${dataPortal.id}.png`} styleType="logo" />
        <div className={styles.boxMenu}>
          <HeaderDashboardMenu dashboard={dashboard} />
        </div>
        {!openSidebar && (
          <Button color="btnSidebar" onClick={sidebarOpen}>
            <Dot />
            <Dot />
            <Dot />
          </Button>
        )}
        {openSidebar && (
          <ModalLayout typeContainer="sidebarContainer" typeModal="sideBar">
            <HeaderDashboardSideBar
              sidebarOpen={sidebarOpen}
              createNew={createNew}
              backToHome={backToHome}
              router={`${router.asPath}`}
              dashboard={dashboard}
            />
          </ModalLayout>
        )}
      </Wrapper>
    </div>
  );
};

HeaderDashboard.defaultProps = {
  dashboard: false
};
HeaderDashboard.propTypes = {
  dashboard: PropTypes.bool
};

export default HeaderDashboard;
