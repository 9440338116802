import PropTypes from "prop-types";

import styles from "../../scss/layouts/Wrapper.module.scss";

const Wrapper = ({ children, styleWrapper, styleContainer }) => (
  <div className={`${styles.container} ${styleContainer && styles[styleContainer]}`}>
    <div className={`${styles.wrapper} ${styleWrapper && styles[styleWrapper]}`}>{children}</div>
  </div>
);
Wrapper.defaultProps = {
  styleWrapper: "",
  styleContainer: false
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  styleWrapper: PropTypes.string,
  styleContainer: PropTypes.any
};

export default Wrapper;
