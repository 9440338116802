import { configureStore } from "@reduxjs/toolkit";
import selectedPublications from "./selectedPublications";
import { localStorageMiddleware } from "./middlewares/localStorage";
import masterOrder from "./masterOrderSlice";

export const store = configureStore({
  reducer: {
    selectedPublications,
    order: masterOrder
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware)
});
