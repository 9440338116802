import { useSelector, useDispatch } from "react-redux";
import { masterOrderSelector, updateOrder, clearOrder } from "../masterOrderSlice";
import { useContext, useEffect, useCallback } from "react";
import { OrderDataContext } from "context/orderContext";
import { getFromLocalStorage } from "utils/storageWorks";

export default function useMasterOrder() {
  const masterOrder = useSelector(masterOrderSelector);
  const dispatch = useDispatch();
  const { setOrderData } = useContext(OrderDataContext);

  const clearMasterOrder = useCallback(() => {
    setOrderData({});
    dispatch(clearOrder());
  }, [dispatch, setOrderData]);

  useEffect(function setInitialPublications() {
    const order = getFromLocalStorage("orderMasterData");
    if (order) {
      dispatch(updateOrder(order));
    }
  }, []);

  function updateMasterOrder(order, force = false) {
    if (force) {
      setOrderData({
        ...order
      });
    } else {
      setOrderData({
        ...masterOrder,
        ...order
      });
    }
    dispatch(updateOrder(order));
  }

  return {
    masterOrder,
    updateMasterOrder,
    clearMasterOrder
  };
}
