/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { createContext, useState, useCallback, useEffect } from "react";
import { setToLocalStorage, getFromLocalStorage } from "utils/storageWorks";
import constants from "../constants/storageWorks";

export const AdBuilderContext = createContext(undefined);

const AD_TEXT_HEIGHTS_KEY = "_ad_text_heights";
const AD_DATA_KEY = "_ad_data";
const IMAGE_KEY = "_image";

export const AdBuilderProvider = (props) => {
  const [adImagePreview, setAdImagePreview] = useState(null);
  const [isGeneratingAdPreview, setIsGeneratingAdPreview] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [adTextHeights, setAdTextHeightsData] = useState({});
  const [adData, _setAdData] = useState(null);
  const [image, _setImage] = useState({});
  const [formIsComplete, setFormIsComplete] = useState(false);

  useEffect(() => {
    if (!adData) return;

    let isFilled = true;
    const tempAdData = adData;

    for (let i = 0; i < tempAdData.length; i++) {
      const element = tempAdData[i];
      if (!element) continue;
      if (
        constants.inputsNameList.includes(element.type) &&
        element.required &&
        element.text !== undefined &&
        (element.text == null || element?.text?.trim() == "")
      ) {
        isFilled = false;
      }
      if (element.type === constants.image && element.required && !image[element.id]?.src) {
        isFilled = false;
      }
    }
    updateAdData(tempAdData);
    setFormIsComplete(isFilled);
  }, [adData]);

  // Hydrate context from local storage on page load
  useEffect(() => {
    if (getFromLocalStorage(IMAGE_KEY)) {
      _setImage(getFromLocalStorage(IMAGE_KEY));
    }

    if (getFromLocalStorage(AD_TEXT_HEIGHTS_KEY)) {
      setAdTextHeightsData(getFromLocalStorage(AD_TEXT_HEIGHTS_KEY));
    }

    if (getFromLocalStorage(AD_DATA_KEY)) {
      setAdData(getFromLocalStorage(AD_DATA_KEY));
    }
  }, []);

  const setAdTextHeights = useCallback((data) => {
    setAdTextHeightsData(data);
    setToLocalStorage(AD_TEXT_HEIGHTS_KEY, data);
  }, []);

  const updateAdData = useCallback((data) => {
    setToLocalStorage(AD_DATA_KEY, data);
    setAdData(data);
  }, []);

  const setImage = useCallback((data) => {
    _setImage(data);
  }, []);

  useEffect(() => {
    setToLocalStorage(IMAGE_KEY, image);
  }, [image]);

  const reset = () => {
    setAdImagePreview(null);
    setIsGeneratingAdPreview(false);
    setLastUpdate(0);
    setFormIsDirty(false);
    setAdTextHeightsData({});
    _setImage({});
    setAdData([]);
  };

  // Helper function in case we want to log
  // changes to adData
  const setAdData = useCallback((data) => {
    _setAdData(data);
  }, []);

  const providerValue = {
    adImagePreview,
    setAdImagePreview,
    isGeneratingAdPreview,
    setIsGeneratingAdPreview,
    lastUpdate,
    setLastUpdate,
    formIsDirty,
    setFormIsDirty,
    setAdTextHeights,
    adTextHeights,
    adData,
    setAdData: updateAdData,
    reset,
    image,
    setImage,
    setImageDirect: _setImage,
    formIsComplete
  };

  return <AdBuilderContext.Provider value={providerValue}>{props.children}</AdBuilderContext.Provider>;
};
