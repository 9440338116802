/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { createContext, useState, useCallback, useEffect } from "react";
import Axios from "axios";
import cookie from "js-cookie";

import { namespaceKey } from "../utils/storageWorks";
import { setToLocalStorage, getFromLocalStorage } from "utils/storageWorks";
import logger from "utils/loggerFrontEnd";

import useProfile from "hooks/useProfile";
import useMasterOrder from "redux/hooks/useMasterOrder";
import withAdBuilder from "hooks/withAdBuilder";

export const AIObitWriterContext = createContext(undefined);

const AI_OBIT_DATA_KEY = "_ai_obit_data";
const AI_ENABLED_KEY = "_ai_enabled";

const INITIAL_STATE = {
  firstName: { value: "", required: true },
  middleName: { value: "", required: false },
  lastName: { value: "", required: true },
  maidenName: { value: "", required: false },
  gender: { value: "", required: true },
  dob: { value: "", required: true },
  dod: { value: "", required: true },
  city: { value: "", required: true },
  state: { value: "", required: true },
  about: { value: "", required: false },
  memorialServices: { value: "", required: false },
  survivors: { value: "", required: false },
  predeceased: { value: "", required: false },
  education: { value: "", required: false },
  militaryService: { value: "", required: false },
  placesLived: { value: "", required: false },
  placesOfWorship: { value: "", required: false },
  career: { value: "", required: false },
  hobbies: { value: "", required: false },
  specialMessage: { value: "", required: false }
};

const FORM = "FORM";
const PREVIEW = "PREVIEW";

export const AIObitWriterProvider = (props) => {
  const [AIEnabled, _setAIEnabled] = useState(false);
  const [showAI, setShowAI] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [aiObitData, _setAIObitData] = useState(null);
  const [obit, setObit] = useState(null);
  const [view, setView] = useState(FORM);

  const { masterOrder } = useMasterOrder();
  const { adData, handleAdData } = withAdBuilder();

  // Hydrate context from local storage on page load
  useEffect(() => {
    if (getFromLocalStorage(AI_ENABLED_KEY)) {
      _setAIEnabled(getFromLocalStorage(AI_ENABLED_KEY));
    }

    if (getFromLocalStorage(AI_OBIT_DATA_KEY)) {
      _setAIObitData(getFromLocalStorage(AI_OBIT_DATA_KEY));
    }
  }, []);

  const { profile } = useProfile();

  useEffect(() => {
    if (!profile) return;

    // hack to enable AI only for Obit Coordinators
    const canUseAI = profile?.userType === "955df8e5-da5a-4994-9684-0a5b11b328ac";

    _setAIEnabled(canUseAI);
    setToLocalStorage(AI_ENABLED_KEY, canUseAI);
  }, [profile]);

  useEffect(() => {
    if (!AIEnabled) return;

    if (!aiObitData) setAIObitData(INITIAL_STATE);
  }, [AIEnabled, aiObitData]);

  const setAIObitData = useCallback(
    (data) => {
      _setAIObitData(data);
      setToLocalStorage(AI_OBIT_DATA_KEY, data);
    },
    [_setAIObitData]
  );

  const resetAIObitData = useCallback(() => {
    setAIObitData(null);
  }, [setAIObitData]);

  const toggleAI = useCallback(() => {
    setShowAI((prevState) => !prevState);
  }, [setShowAI]);

  const submitData = useCallback(
    async (e) => {
      e.preventDefault();

      setProcessing(true);

      // build the data object
      const data = Object.keys(aiObitData).reduce((acc, key) => {
        const value = aiObitData?.[key]?.value;

        if (value) {
          acc[key] = value;
        }

        return acc;
      }, {});

      const token = cookie.get(namespaceKey("auth")) ? JSON.parse(cookie.get(namespaceKey("auth"))).token : "";

      await logger.info("Submitting Obit Writer Form", { orderId: masterOrder.id, data });

      // submit the data
      try {
        const response = await Axios.post("/api/obit-writer", {
          form: { ...data },
          orderId: masterOrder?.id,
          portalId: masterOrder?.portal?.id,
          token
        });

        setProcessing(false);
        setView(PREVIEW);

        const obitResponse = response?.data?.data;

        if (!obitResponse) {
          await logger.error("Error submitting Obit Writer Form", {
            orderId: masterOrder.id,
            response: response?.data
          });

          throw new Error("No obituary data returned from CreateObitFromDataForm API");
        }

        // replace all \n\n with \n to avoid double spacing
        obitResponse.ObituaryText = obitResponse.ObituaryText.replace(/\n\n/g, "\n");

        console.log("Obit Writer Form Response", { orderId: masterOrder.id, obitResponse });

        setObit(obitResponse);
      } catch (err) {
        setProcessing(false);
        console.warn(err.message);
      }
    },
    [aiObitData, masterOrder]
  );

  const handleChange = useCallback(
    (e) => {
      setAIObitData({
        ...aiObitData,
        [e.target.name]: {
          ...aiObitData[e.target.name],
          value: e.target.value
        }
      });
    },
    [aiObitData, setAIObitData]
  );

  const get = useCallback(
    (key) => {
      return aiObitData?.[key]?.value || "";
    },
    [aiObitData]
  );

  const resetForm = useCallback(
    (e) => {
      e.preventDefault();
      setAIObitData(INITIAL_STATE);
    },
    [setAIObitData]
  );

  const useObituary = useCallback(
    async (e) => {
      e.preventDefault();

      const obitUpdates = [];

      function getElementIdFromFieldId(fieldId) {
        const indexItem = adData.findIndex((el) => el?.dataSpecialTemplateFieldId === fieldId);
        return adData?.[indexItem]?.id;
      }

      function _handleAdData(obitUpdate) {
        obitUpdates.push(obitUpdate);
      }

      function handleObitUpdates() {
        handleAdData(obitUpdates);
      }

      const contentId = getElementIdFromFieldId(process.env.NEXT_PUBLIC_OBIT_CONTENT_FIELD_ID);
      const nameId = getElementIdFromFieldId(process.env.NEXT_PUBLIC_OBIT_DECEASED_NAME_FIELD_ID);
      const residentId = getElementIdFromFieldId(process.env.NEXT_PUBLIC_OBIT_RESIDENT_OF_FIELD_ID);
      const lifespanId = getElementIdFromFieldId(process.env.NEXT_PUBLIC_OBIT_LIFESPAN_FIELD_ID);

      if (contentId) {
        _handleAdData({
          target: {
            id: contentId,
            value: obit.ObituaryText || ""
          }
        });
      }

      if (nameId) {
        _handleAdData({
          target: {
            id: nameId,
            value: obit.FullName
          }
        });
      }

      if (residentId) {
        _handleAdData({
          target: {
            id: residentId,
            value: `Resident of ${obit.PlaceOfDeath}`
          }
        });
      }

      if (lifespanId) {
        _handleAdData({
          target: {
            id: lifespanId,
            value: `${obit.Lifespan}`
          }
        });
      }

      handleObitUpdates();

      toggleAI();
    },
    [adData, obit, aiObitData, get]
  );

  const providerValue = {
    AIEnabled,
    showAIUI: showAI,
    aiObitData,
    setAIObitData,
    toggleAI,
    submitData,
    handleChange,
    get,
    resetForm,
    processing,
    view,
    obit,
    useObituary,
    setView,
    resetAIObitData
  };

  return <AIObitWriterContext.Provider value={providerValue}>{props.children}</AIObitWriterContext.Provider>;
};
