import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import { useApollo } from "../../apollo/client";
import Layout from "../Layout";
import React from "react";
import { GlobalContextProvider } from "../../context";
import { store } from "../../redux";
import PortalProvider from "../../context/portalContext";

const Apollo = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps.initialApolloState);
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <PortalProvider portalId={pageProps?.portalId}>
          <GlobalContextProvider portalId={pageProps?.portalId}>
            <Layout portalId={pageProps?.portalId}>
              <Component {...pageProps} />
            </Layout>
          </GlobalContextProvider>
        </PortalProvider>
      </ApolloProvider>
    </Provider>
  );
};

Apollo.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({
    initialApolloState: PropTypes.shape({})
  }).isRequired
};

export default Apollo;
