import { get, isEmpty } from "lodash";

import useMasterOrder from "src/redux/hooks/useMasterOrder";

export default function useCheckScheduleChange() {
  const { masterOrder } = useMasterOrder();

  // Calculate the total number of days in the schedule
  return function checkScheduleChange() {
    if (isEmpty(masterOrder)) return false;
    return masterOrder?.selectedPublications?.reduce((total2, publication) => {
      const numDays = get(masterOrder, `publicationOrderData.${publication?.id}.schedule.length`);

      if (isNaN(numDays)) {
        return total2;
      }
      return total2 + numDays;
    }, 0);
  };
}
