import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";
import Button from "../UI/Button";
import Dot from "../UI/Dot";
import styles from "../../../scss/components/headerDashboard/HeaderDashboardSideBar.module.scss";
import HeaderDashboardMobileMenu from "./HeaderDashboardMobileMenu";

const HeaderDashboardSideBar = ({ t, sidebarOpen, createNew, backToHome, router, dashboard }) => (
  <div className={styles.container}>
    <div className={styles.close}>
      <Button color="btnSidebar" onClick={sidebarOpen}>
        <Dot />
        <Dot />
        <Dot />
      </Button>
    </div>
    <div className={styles.btnCreate}>
      <Button color="sidebarBtn" onClick={createNew}>
        {t("createNew")}
      </Button>
    </div>
    <HeaderDashboardMobileMenu router={router} dashboard={dashboard} />
    <Button color="sidebarBtnGrey" onClick={backToHome}>
      {t("backToHome")}
    </Button>
  </div>
);

HeaderDashboardSideBar.defaultProps = {
  sidebarOpen: () => {},
  createNew: () => {},
  backToHome: () => {},
  router: "",
  dashboard: false
};
HeaderDashboardSideBar.propTypes = {
  t: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.func,
  createNew: PropTypes.func,
  backToHome: PropTypes.func,
  router: PropTypes.string,
  dashboard: PropTypes.bool
};

export default withTranslation("indexPage")(HeaderDashboardSideBar);
