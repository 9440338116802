/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-use-before-define */
import React, { createContext, useState, useEffect, useCallback, useContext } from "react";
import { getFromLocalStorage, setToLocalStorage, removeFromLocalStorage } from "../utils/storageWorks";
import constants from "../constants/storageWorks";
import initialCalendarState from "../constants/publicationDate";
import { AppContext } from "./appContext";

const SELECTED_TEMPLATE_KEY = "_selected_template";
const SCHEDULE_DATES_KEY = "_schedule_dates";
const CALENDAR_STATES_KEY = "_calendar_states";

const OrderDataContext = createContext(undefined);
import useDataPortal from "hooks/useDataPortal";

const LOG_ORDER_UPDATES = false;

const OrderDataProvider = (props) => {
  const { selectItem } = useContext(AppContext);
  const dataPortal = useDataPortal();

  const [orderData, _setOrderData] = useState(undefined);
  const [selectedTemplate, _setSelectedTemplate] = useState({});
  const [scheduleDates, _setScheduleDates] = useState([]);
  const [calendarStates, _setCalendarStates] = useState([
    {
      ...initialCalendarState
    }
  ]);

  const updateOrderData = useCallback(
    (newData) => {
      LOG_ORDER_UPDATES && console.log("updateOrderData", newData);

      const newOrderData = {
        ...orderData,
        ...newData
      };

      _setOrderData(newOrderData);
      setToLocalStorage(constants.orderMasterData, newOrderData);
    },
    [orderData]
  );

  const setOrderData = useCallback((newData) => {
    LOG_ORDER_UPDATES && console.log("setOrderData", newData);
    _setOrderData(newData);
    setToLocalStorage(constants.orderMasterData, newData);
  }, []);

  const clearOrderData = useCallback(() => {
    _setOrderData({});
    _setSelectedTemplate({});
    _setScheduleDates([]);
    _setCalendarStates([
      {
        ...initialCalendarState
      }
    ]);

    const keys = [
      [constants.publication],
      [constants.publicationId],
      [constants.filledTemplateDigital],
      [constants.pricePackage],
      [constants.dataPortal],
      [constants.adData],
      [constants.order],
      [constants.orderID],
      [constants.orderMasterData],
      [constants.scheduling],
      "_selected_template",
      "_schedule_dates",
      "_ad_text_heights",
      "_ad_data",
      "_calendar_states",
      "_image"
    ];

    keys.forEach((key) => removeFromLocalStorage(key));

    setToLocalStorage("dataPortal", { portalID: dataPortal.portalID });
  }, [dataPortal]);

  const setSelectedTemplate = useCallback((data) => {
    _setSelectedTemplate(data);
    setToLocalStorage(SELECTED_TEMPLATE_KEY, data);
  }, []);

  const setScheduleDates = useCallback((data) => {
    _setScheduleDates(data);
    setToLocalStorage(SCHEDULE_DATES_KEY, data);
  }, []);

  const setCalendarStates = useCallback((data) => {
    _setCalendarStates(data);
    setToLocalStorage(CALENDAR_STATES_KEY, data);
  }, []);

  useEffect(function hydrateOrderContextFromLocalStorage() {
    _setOrderData(getFromLocalStorage(constants.orderMasterData));

    if (getFromLocalStorage(SELECTED_TEMPLATE_KEY)) {
      _setSelectedTemplate(getFromLocalStorage(SELECTED_TEMPLATE_KEY));
    }

    // hydrating these two variables caused a bug
    // when you reloaded the page the calendar states object
    // was not being initialized correctly and you could not
    // select a date

    if (getFromLocalStorage(SCHEDULE_DATES_KEY)) {
      _setScheduleDates(getFromLocalStorage(SCHEDULE_DATES_KEY));
    }

    // if (getFromLocalStorage(CALENDAR_STATES_KEY)) {
    //   _setCalendarStates(getFromLocalStorage(CALENDAR_STATES_KEY));
    // }
  }, []);

  useEffect(() => {
    if (orderData?.selectedPackage) {
      selectItem({ stage: 1, title: "Package", item: [orderData.selectedPackage.name] });
    } else {
      selectItem({ stage: 1, title: "", item: [] });
    }
  }, [orderData?.selectedPackage]);

  const providerValue = {
    orderData,
    updateOrderData,
    clearOrderData,
    selectedTemplate,
    setSelectedTemplate,
    scheduleDates,
    setScheduleDates,
    calendarStates,
    setCalendarStates,
    setOrderData
  };

  return (
    <OrderDataContext.Provider displayName="Order Context" value={providerValue}>
      {props.children}
    </OrderDataContext.Provider>
  );
};

export { OrderDataContext, OrderDataProvider };
