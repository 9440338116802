import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";
import Wrapper from "../Wrapper";
import styles from "../../../scss/components/footer/Footer.module.scss";
import info from "../../../package.json";

const VERCEL_ENV = process?.env?.NEXT_PUBLIC_VERCEL_ENV || "DEV";
const VERCEL_BRANCH = process?.env?.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || "DEV";

function versionInfo() {
  if (VERCEL_ENV === "production") return `${info.version}.`;

  return `${info.version}.${VERCEL_ENV.toUpperCase()}.${VERCEL_BRANCH.toUpperCase()}`;
}

function copyRightDate() {
  return new Date().getFullYear();
}

const Footer = ({ styleFooter }) => (
  <Wrapper
    styleWrapper={styleFooter ? "wrapperFooter" : "wrapperFooterLeft"}
    styleContainer={!styleFooter && "containerHide"}
  >
    <div className={styles.termsBox}>
      <p>
        {`Copyright Bay Area News Group `}&copy;
        {` ${copyRightDate()}. Version: ${versionInfo()}`}
      </p>
    </div>
    <div className={styles.termsBox}>
      <a href="https://www.medianewsgroup.com/privacy-policy/" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      <p>|</p>
      <a href="https://www.medianewsgroup.com/cookie-policy/" target="_blank" rel="noreferrer">
        Cookie Policy
      </a>
      <p>|</p>
      <a href="https://www.medianewsgroup.com/california-consumer-privacy-act/" target="_blank" rel="noreferrer">
        Do Not Sell My Information
      </a>
    </div>
  </Wrapper>
);

Footer.defaultProps = {
  styleFooter: ""
};

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  styleFooter: PropTypes.bool
};

export default withTranslation("indexPage")(Footer);
