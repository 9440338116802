import { get } from "lodash";
import { KEYS_BY_DISPATCH_EVENTS } from "../middlewares/localStorageConfig";
import { setToLocalStorage } from "utils/storageWorks";

export const localStorageMiddleware = (storeAPI) => (next) => (action) => {
  let eventKeys = get(KEYS_BY_DISPATCH_EVENTS, action.type);

  let result = next(action);

  if (!eventKeys) {
    console.warn(`NO LOCAL STORAGE HANDLING FOR ACTION ${action.type}`);
  } else {
    setToLocalStorage(eventKeys.localStorageKey, storeAPI.getState()[eventKeys.storeKey].value);
  }

  return result;
};
