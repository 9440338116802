/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from "react";
import i18n from "../../i18n/i18n";
import Apollo from "../layouts/Apollo/Apollo";
import "../../scss/main.scss";
import "../../scss/clear-cache.scss";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import App from "next/app";

import getPortalFromRequest from "utils/getPortalFromRequest";

import { tracker } from "utils/snowplow";

const CustomApp = (props) => {
  useEffect(() => {
    tracker.init(props?.pageProps?.portalId);
  }, []);

  return (
    <>
      <Apollo {...props} />
    </>
  );
};

export default i18n.appWithTranslation(CustomApp);

CustomApp.getInitialProps = async (appContext) => {
  let result = {
    pageProps: {}
  };

  try {
    result = await App.getInitialProps(appContext);

    let portal = {};

    if (appContext?.ctx?.req?.url) {
      portal = await getPortalFromRequest(appContext.ctx);
    }

    result.pageProps = {
      ...result.pageProps,
      portalId: portal?.id
    };
  } catch (e) {}

  return { ...result };
};
