import { useCallback, useState, useEffect } from "react";

import useProfile from "hooks/useProfile";

const validUserTypes = [process.env.NEXT_PUBLIC_COORDINATOR_USER_TYPE, process.env.NEXT_PUBLIC_ADMIN_USER_TYPE];

export default function usePriceInspector() {
  const { profile } = useProfile();

  const [showPricing, setShowPricing] = useState(false);
  const [pricing, setPricing] = useState({});

  const canInspectPricing = useCallback(() => {
    return validUserTypes.includes(profile?.userType);
  }, [validUserTypes, profile?.userType]);

  const togglePricing = async () => {
    const element = document.getElementById("pricing-inspector");

    if (!element) return;

    if (showPricing) {
      element.style.display = "none";
    } else {
      element.style.display = "block";
    }

    setShowPricing(!showPricing);
  };

  function generatePricingInspector({
    pricingAdjustments,
    templatePricing,
    adTextHeights,
    adData,
    publication,
    duration,
    pricingAdjustmentTotals,
    templatePricingTotal
  }) {
    const paObject = createPricingObject({
      pricingAdjustments,
      templatePricing,
      adTextHeights,
      adData,
      publication,
      duration,
      pricingAdjustmentTotals,
      templatePricingTotal
    });

    const paArray = Object.keys(paObject).map((key) => {
      return { ...paObject[key], id: key };
    });

    let text = paArray.reduce((temp, item) => {
      return `${temp}<tr><td>${item.id}</td><td>${item.amount}</td></tr>`;
    }, "");

    text = `<table>${text}</table>`;

    setPricing((prevState) => {
      return {
        ...prevState,
        [publication.id]: text
      };
    });
  }

  useEffect(() => {
    if (!canInspectPricing()) return;

    const pricingInspector = document.getElementById("pricing-inspector");

    if (pricingInspector) {
      pricingInspector.innerHTML = Object.values(pricing).join("");
    }
  }, [canInspectPricing, pricing]);

  function createPricingObject({
    pricingAdjustments,
    publication,
    templatePricing,
    pricingAdjustmentTotals,
    templatePricingTotal,
    duration,
    adData,
    adTextHeights
  }) {
    let grandTotal = 0;

    const paObject = pricingAdjustments.reduce(
      (acc, item) => {
        // format item.value to be a float

        grandTotal += item.value;

        const amount = item.value / 100;

        // give amount 2 decimal places as a float

        const amount2 = parseFloat(amount.toFixed(2));

        acc[item.name] = { amount: amount2 };
        return acc;
      },
      {
        [`***${publication.name} Pricing`]: { amount: "" },
        "***Start Pricing Adjustments***": { amount: "" }
      }
    );

    paObject["***End Pricing Adjustments*****"] = { amount: "" };

    paObject["***Pricing Adjustments Total***"] = { amount: pricingAdjustmentTotals / 100 };

    paObject["******************************* "] = { amount: "" };

    paObject["***Pricing Adjustments Total***"] = { amount: pricingAdjustmentTotals / 100 };

    paObject["***Start Template Pricing******"] = { amount: "" };

    let dailyTotal = 0;

    Object.entries(templatePricing).forEach(([key, value]) => {
      if (key === "undefined") return;

      const formattedPricePerLine = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
        value / 100
      );

      const numLines = adTextHeights?.[key] || 0;

      if (numLines === 0) return;

      const v = value * numLines;

      const item = adData.find((item) => item.id === key);

      if (!item) return;

      if (item.type !== "image") {
        paObject[`${key} (${formattedPricePerLine} x ${numLines} ${numLines === 1 ? "line" : "lines"})`] = {
          amount: v / 100
        };
        dailyTotal += v;
      }
    });

    grandTotal += dailyTotal * duration;

    paObject["***End Template Pricing********"] = { amount: "" };
    paObject["***Template Pricing Total******"] = { amount: dailyTotal / 100 };

    paObject["***Duration********************"] = { amount: duration };

    paObject["***Daily Total*****************"] = { amount: (dailyTotal * duration) / 100 };

    paObject["*******************************  "] = { amount: "" };

    let imageTotal = 0;

    paObject["***Start Image Pricing*********"] = { amount: "" };

    Object.entries(templatePricing).forEach(([key, value]) => {
      if (key === "undefined") return;

      const formattedPricePerLine = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
        value / 100
      );

      const item = adData.find((item) => item.id === key);

      if (!item) return;

      if (item.type === "image") {
        const adItem = adData.find((item) => item.id === key);

        if (!adItem.url) return;

        paObject[`${key} (${formattedPricePerLine})`] = { amount: value / 100 };
        imageTotal += value;
      }
    });

    grandTotal += imageTotal;

    paObject["***End Image Pricing***********"] = { amount: "" };
    paObject["***Image Pricing Total*********"] = { amount: imageTotal / 100 };

    paObject["*******************************   "] = { amount: "" };

    paObject[`***${publication.name} TOTAL`] = {
      amount: ""
    };

    paObject[
      `***(${pricingAdjustmentTotals / 100} + ${(templatePricingTotal * duration) / 100} + ${imageTotal / 100})`
    ] = {
      amount: grandTotal / 100
    };

    return paObject;
  }

  return {
    generatePricingInspector,
    canInspectPricing,
    togglePricing,
    showPricing,
    createPricingObject
  };
}
