/* eslint-disable global-require */
/* because this is server part */
import { useMemo } from "react";
import { createUploadLink } from "apollo-upload-client";
import { ApolloClient, InMemoryCache, ApolloLink, from, makeVar, fromPromise } from "@apollo/client";
import cookie from "js-cookie";
import { onError } from "@apollo/client/link/error";
import { getNewToken, namespaceKey } from "../utils/storageWorks";
import cookiesWorker from "../utils/cookiesWorker";
import { accessToken, refreshToken } from "../context/appContext";

let apolloClient;

export const lastActiveHref = makeVar("");

function createIsomorphLink() {
  if (typeof window === "undefined") {
    const { schema } = require("./schema");
    const { SchemaLink } = require("@apollo/client/link/schema");
    return new SchemaLink({ schema });
  }

  return createUploadLink({
    uri: process.env.NEXT_PUBLIC_apiUrl,
    credentials: "same-origin"
  });
}

const { removeCookie } = cookiesWorker();

// eslint-disable-next-line consistent-return
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
      if (operation.operationName === "refreshToken" && graphQLErrors[0].message.indexOf("jwt expired") > -1) {
        removeCookie("auth");
        accessToken(null);
        refreshToken(null);
      } else {
        return fromPromise(
          getNewToken(apolloClient).catch(() => {
            removeCookie("auth");
            accessToken(null);
            refreshToken(null);
          })
        )
          .filter((value) => Boolean(value))
          .flatMap((token) => {
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${token}`
              }
            });

            // retry the request, returning the new observable
            return forward(operation);
          });
      }
    }
  }
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = cookie.get(namespaceKey("auth")) ? JSON.parse(cookie.get(namespaceKey("auth"))).token : "";
    // const token = accessToken();
    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      };
    }
    return {
      headers: {
        ...headers
      }
    };
  });
  return forward(operation);
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([errorLink, authLink, createIsomorphLink()]),
    cache: new InMemoryCache({
      addTypename: false
    })
  });
}

export function initializeApollo(initialState = null) {
  const initApolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    initApolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return initApolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = initApolloClient;
  }

  return initApolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
