export default function getImage(src) {
  return new Promise((resolve, reject) => {
    if (!src) return reject(new Error(`getImage: src is null`));

    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error(`Could not load image : ${src}`));
    img.src = src;
  });
}
