import moment from "moment-timezone";

import { getWeekDay } from "./getWeekDay";

const TIME_ZONE = "America/Los_Angeles";

/**
 * Checks if the publication is allowed to be booked on the given date.
 * @param {*} daysCanPublish array of days and the publishing times
 * @param {*} param1 - year, month, day
 * @returns Boolean
 */
export const isPublishingDayAllowed = (daysCanPublish, { year, month, day }) => {
  if (!daysCanPublish.length) return false;
  const rightNow = moment.tz(new Date(), TIME_ZONE);
  const weekday = getWeekDay(day, year, month);
  const deadline = daysCanPublish.find((deadline) => {
    return deadline.publishDay.toLowerCase() === weekday;
  });

  if (!deadline?.cutoffTime) return false;

  const hour = deadline.cutoffTime.hour;
  const minute = deadline.cutoffTime.minute;
  const dayTime = deadline.cutoffTime.dayTime;

  // moment uses 1 based month
  const dateString = `${year}-${month + 1}-${day} ${hour}:${minute} ${dayTime}`;

  const cutoff = moment.tz(dateString, "YYYY-M-D h:mm A", TIME_ZONE);

  cutoff.subtract(deadline.publishingOffsetInHours, "hours");

  return rightNow.isBefore(cutoff);
};
