/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { useState, useContext } from "react";
import constants from "../constants/storageWorks";
import {
  setToLocalStorage,
  getFromLocalStorage,
  removeLocalStorage,
  filterArray,
  splitStyleSize
} from "../utils/storageWorks";
import { cloneDeep } from "lodash";

import { AdBuilderContext } from "context/adBuilderContext";

const withAdBuilder = () => {
  const { adData, setAdData } = useContext(AdBuilderContext);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // const handleAdData = (event) => {
  //   let m_adData = adData;

  //   const { id, value } = event.target;

  //   const newArray = [];
  //   const indexItem = m_adData.findIndex((el) => el.id === id);
  //   const itemObj = m_adData[indexItem];

  //   let body = cloneDeep(itemObj);

  //   Object.keys(body).forEach((item) => {
  //     body[item] = itemObj[item];
  //   });

  //   body.text = value;

  //   m_adData.forEach((item) => {
  //     newArray.push(item);
  //   });

  //   newArray[indexItem] = body;

  //   setAdData(newArray);
  // };

  const handleAdData = (events) => {
    let m_adData = [...adData];

    // If events is not an array, make it an array with a single element
    if (!Array.isArray(events)) {
      events = [events];
    }

    events.forEach((event) => {
      const { id, value } = event.target;
      const indexItem = m_adData.findIndex((el) => el.id === id);
      const itemObj = m_adData[indexItem];

      let body = cloneDeep(itemObj);

      Object.keys(body).forEach((item) => {
        body[item] = itemObj[item];
      });

      body.text = value;
      m_adData[indexItem] = body;
    });

    setAdData(m_adData);
  };

  const getSelectedTemplate = () => {
    if (selectedTemplate) {
      return selectedTemplate;
    }

    const data = getFromLocalStorage(constants.filledTemplateDigital);
    setSelectedTemplate(data);

    return data;
  };

  // This is the function that
  // takes the template and removes the placeholder values
  //
  // This gets called in choose-template
  const getFromTemplate = async (elements) => {
    removeLocalStorage(constants.adData);

    if (elements && elements.length > 0) {
      let dataList = [];

      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        let m_element = { ...element };
        if (constants.inputsNameList.includes(m_element.type) && m_element.text && m_element.text != "") {
          m_element.text = "";
        }
        if (m_element.url && m_element.url != "") {
          // copy the values from the template to the adData
          // so we can use them in the ad builder
          // otherwise height was being set to NaN
          m_element.templateHeight = m_element.height;
          m_element.templateWidth = m_element.width;

          m_element.url = null;
        }

        if (m_element.type === "description") {
          m_element.multiline = true;
        }

        dataList.push(m_element);
      }

      setToLocalStorage(constants.adData, dataList);
      setAdData(dataList);
      return dataList;
    } else {
      return adData;
    }
  };

  function getImageHeights(_adData) {
    let clonedData = cloneDeep(_adData);

    for (let el of clonedData) {
      if (el.type === "image") {
        const { imageWidth, imageHeight } = el;
        const maxHeight = parseInt(el.height);

        let targetWidth = el.width;
        // remove px and convert to number
        targetWidth = parseInt(targetWidth);
        const targetHeight = (targetWidth * imageHeight) / imageWidth;

        el.height = targetHeight;
        if (targetHeight > maxHeight) {
          el.height = maxHeight;
        }

        if (isNaN(el.height)) {
          el.height = maxHeight;
        }

        el.alignment = "center";
      }
    }
    return clonedData;
  }

  function calculateMoveHeight(_adData) {
    let clonedData = cloneDeep(_adData);
    let moveIndex;
    let maxImageHeight = 0;
    let imageVerticalMargins = 0;
    let allImgNull = true;

    clonedData.forEach((el, index) => {
      if (el.type === "image") {
        if (el.height > maxImageHeight) {
          maxImageHeight = el.height;
          imageVerticalMargins =
            Number(splitStyleSize(filterArray(el.margin, constants.marginTop)[0].value)) +
            Number(splitStyleSize(filterArray(el.margin, constants.marginBottom)[0].value));
        }

        if (allImgNull) allImgNull = !el.url;
      }

      if (allImgNull) {
        maxImageHeight = 0;
        imageVerticalMargins = 0;
      }

      if (el.type === "move") moveIndex = index;
    });

    if (moveIndex !== undefined) {
      clonedData[moveIndex].height = maxImageHeight;
      clonedData[moveIndex].y = maxImageHeight + imageVerticalMargins;
    }

    return clonedData;
  }

  const calculateAdData = async () => {
    try {
      return calculateMoveHeight(getImageHeights(adData));
    } catch (e) {
      console.log(e.message);
      return adData;
    }
  };

  return {
    adData,
    setAdData,
    getFromTemplate,
    handleAdData,
    selectedTemplate,
    getSelectedTemplate,
    calculateAdData
  };
};

export default withAdBuilder;
