import { isPast, endOfDay } from "date-fns";
import { isPublishingDayAllowed } from "./isPublishingDayAllowed";

/**
 *
 * @param {Object} day to check if can be booked
 * @param {Array of Object} publicationDays array of days and the publishing times
 * @returns string button class name
 */
export const getCalendarDayColor = ({ year, month, day }, publicationDays) => {
  const certainDate = endOfDay(new Date(year, month, day));
  const isPastDate = isPast(certainDate);

  if (isPastDate) {
    return "buttonDayChoosePast";
  }

  const daysCanPublish = publicationDays
    .filter(([, value]) => !!value)
    .map(([key, value]) => ({ publishDay: key.toLowerCase(), ...value }));

  const isDayOfPublication = isPublishingDayAllowed(daysCanPublish, { year, month, day });

  if (isDayOfPublication) {
    return "buttonDayChoose";
  }

  return "buttonDayChooseUnavailable";
};
