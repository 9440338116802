import { useCallback } from "react";

import { removeFromLocalStorage } from "utils/storageWorks";

import { PROMO_COUPON_KEY } from ".";

export default function useResetPricing({ setTotal, setFormattedTotal, setPromoCoupon }) {
  return useCallback(() => {
    setTotal(null);
    setFormattedTotal(null);
    setPromoCoupon(null);
    removeFromLocalStorage("_pricing_calc");
    removeFromLocalStorage("_pricing");
    removeFromLocalStorage(PROMO_COUPON_KEY);
  }, []);
}
