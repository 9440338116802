import React, { createContext, useState, useEffect, useCallback, useContext } from "react";
import { getFromLocalStorage, setToLocalStorage, removeFromLocalStorage } from "../utils/storageWorks";
import { AppContext } from "./appContext";

const PublicationContext = createContext(undefined);

const PUBLICATION_KEY = "publication";

const PublicationProvider = (props) => {
  const [currentPublication, setCurrentPublication] = useState(undefined);

  const { selectItem } = useContext(AppContext);

  const selectPublication = useCallback((data) => {
    // testing if this is used anywhere
    return;
    setCurrentPublication(data);

    if (data === null) {
      removeFromLocalStorage(PUBLICATION_KEY);
    } else {
      setToLocalStorage(PUBLICATION_KEY, data);
    }
  }, []);

  const getPublication = useCallback(() => {
    console.log("getPublication() was called.  Why????");
    return null;
    const data = getFromLocalStorage(PUBLICATION_KEY);
    setCurrentPublication(data);
    return data;
  }, []);

  // eslint-disable-next-line prefer-arrow-callback
  useEffect(function getPublicationDataFromLocalStorage() {
    setCurrentPublication(getFromLocalStorage(PUBLICATION_KEY));
  }, []);

  useEffect(() => {
    if (currentPublication) {
      selectItem({ stage: 0, title: "Publication", item: [currentPublication.name] });
    } else {
      selectItem({ stage: 0, title: "", item: [] });
    }
  }, [currentPublication]);

  const providerValue = {
    currentPublication,
    selectPublication,
    getPublication
  };

  return <PublicationContext.Provider value={providerValue}>{props.children}</PublicationContext.Provider>;
};

export { PublicationContext, PublicationProvider };
