import { useEffect } from "react";

import { getFromLocalStorage } from "utils/storageWorks";

import { PROMO_COUPON_KEY } from ".";

export default function useHydratePricingContextFromLocalStorage({ setPromoCoupon }) {
  useEffect(function hydratePricingContextFromLocalStorage() {
    if (getFromLocalStorage(PROMO_COUPON_KEY)) {
      setPromoCoupon(getFromLocalStorage(PROMO_COUPON_KEY));
    }
  }, []);
}
