import { useContext, useEffect } from "react";
import { AppContext } from "../context";

// Added manual export of userId
// So that it can be used in loggerFrontEnd.js

let userId = "";

function useProfile() {
  const { profile, setProfile } = useContext(AppContext);

  useEffect(() => {
    userId = profile?.id;
  }, [profile]);

  return {
    profile,
    setProfile
  };
}

export default useProfile;

export { userId };
