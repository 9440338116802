import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";
import Logo from "../general/Logo";
import Wrapper from "../../layouts/Wrapper";
import HeaderMenu from "./HeaderMenu";
import Button from "../UI/Button";
import Dot from "../UI/Dot";
import HeaderSideBar from "./HeaderSideBar";
import ModalLayout from "../../layouts/ModalLayout";
import styles from "../../../scss/components/header/HeaderHomePage.module.scss";

import useDataPortal from "hooks/useDataPortal";

const HeaderHomePage = ({ t, authStatus, sidebarOpen, createAnAd, openSidebar, styleMobile, listMenu }) => {
  const dataPortal = useDataPortal();

  return (
    <div className={`${styles.container} ${styleMobile && styles[styleMobile]}`}>
      <Wrapper styleWrapper="wrapperHeader">
        <Logo
          href="/"
          text={t("obituaryNotices")}
          urlLogo={`/static/images/portals/${dataPortal.id}.png`}
          styleType="logo"
        />
        <div className={styles.menuBox}>
          <HeaderMenu authStatus={authStatus} sidebarOpen={sidebarOpen} listMenu={listMenu} />
        </div>
        {!openSidebar && (
          <Button color="btnSidebar" onClick={sidebarOpen}>
            <Dot />
            <Dot />
            <Dot />
          </Button>
        )}
        {openSidebar && (
          <ModalLayout typeContainer="sidebarContainer" typeModal="sideBar">
            <HeaderSideBar
              textBtn={t("createAnAd")}
              createAnAd={createAnAd}
              sidebarOpen={sidebarOpen}
              authStatus={authStatus}
              listMenu={listMenu}
            />
          </ModalLayout>
        )}
      </Wrapper>
    </div>
  );
};

HeaderHomePage.defaultProps = {
  authStatus: false,
  sidebarOpen: () => {},
  createAnAd: () => {},
  openSidebar: false,
  styleMobile: "",
  listMenu: []
};

HeaderHomePage.propTypes = {
  t: PropTypes.func.isRequired,
  authStatus: PropTypes.bool,
  openSidebar: PropTypes.bool,
  sidebarOpen: PropTypes.func,
  createAnAd: PropTypes.func,
  styleMobile: PropTypes.string,
  listMenu: PropTypes.arrayOf(PropTypes.shape({}))
};

export default withTranslation("indexPage")(HeaderHomePage);
