import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "@apollo/client/utilities";

const initialState = {
  value: []
};

export const selectedPublicationsSlice = createSlice({
  name: "selectedPublications",
  initialState,
  reducers: {
    addPublication(state, { payload }) {
      state.value = [...state.value, payload];
    },
    setPublications(state, { payload }) {
      state.value = payload;
    },
    splicePublications(state, { payload }) {
      const publicationState = clonePublications(state.value);
      publicationState[payload.index] = payload.publication;
      state.value = publicationState;
    },
    removePub(state, { payload }) {
      const removalId = payload;
      const publicationState = clonePublications(state.value);
      const index = publicationState.findIndex(({ id }) => id === removalId);
      publicationState.splice(index, 1);
      state.value = publicationState;
    }
  }
});

function clonePublications(publicationsState) {
  return cloneDeep(publicationsState);
}

// Action creators are generated for each case reducer function
export const { addPublication, splicePublications, removePub, setPublications } = selectedPublicationsSlice.actions;

export const selectedPublicationsSelector = (state) => state.selectedPublications.value;

export default selectedPublicationsSlice.reducer;
