/* eslint-disable no-unused-vars */
import Cookies from "cookies";
import { namespaceKey } from "./storageWorks";

const PORTAL_ID_OVERRIDE_KEY = "say-hello-to-my-little-friend";
const BANG_PORTAL_ID = "c3cc0cfa-9c1e-4bb7-bf2c-cb2c79a7dd4a";
const NORCAL_PORTAL_ID = "norcal-portal";
const SCNG_PORTAL_ID = "scng-portal";
const SDUT_PORTAL_ID = "sdut-portal";

/**
 * Kung fu magic
 * @param {*} param0
 * @returns
 */
export default async function getPortalFromRequest(ctx) {
  let url;
  let hostname;
  let portalId;
  let portalCookie;
  let portal;
  let cookies;
  let override = false;
  const query = {};
  let urlParts;
  let dataSource;
  let savedCookie = false;

  try {
    url = ctx?.req?.url || "";
    hostname = ctx?.req?.headers?.host;

    if (url === "") {
      return {
        id: null
      };
    }

    // if url ends with .css.map or .js.map, return null
    if (url.endsWith(".css.map") || url.endsWith(".js.map")) {
      return {
        id: null
      };
    }

    // if the url starts with /public/static/ then return null
    // this is because the i18n files are served by the nextjs server
    if (url.startsWith("/public/static/")) {
      return {
        id: null
      };
    }

    if (url.startsWith("/static/")) {
      return {
        id: null
      };
    }

    // extract query params from url
    urlParts = url.split("?");
    if (urlParts.length > 1) {
      const queryParams = urlParts[1].split("&");
      queryParams.forEach((param) => {
        const paramParts = param.split("=");
        query[paramParts[0]] = paramParts[1];
      });
    }

    try {
      cookies = new Cookies(ctx.req, ctx.res);

      if (cookies) {
        portalCookie = cookies.get(namespaceKey("dataPortal"));

        if (portalCookie) {
          portal = JSON.parse(portalCookie);

          if (!portal?.id) {
            throw new Error();
          }

          dataSource = "COOKIE";
          portalId = portal?.id;
        }
      }
    } catch (e) {
      portalCookie = null;
    }

    const portalIdOverride = query?.["portal-id-override"];
    const portalIdOverrideKey = query?.["portal-id-override-key"];

    if (portalIdOverride && portalIdOverride !== "" && portalIdOverrideKey === PORTAL_ID_OVERRIDE_KEY) {
      override = true;
      portalId = portalIdOverride;
      dataSource = "OVERRIDE";
    }

    if (process.env.HOST_OVERRIDE) {
      portalId = process.env.HOST_OVERRIDE;
    }

    if (!portalId) {
      // if portal is not found in cookies or override, get portal from request
      // will be used in next version
      // query database for portal id

      dataSource = "QUERY";

      switch (hostname) {
        case "scngobits.com":
        case "www.scngobits.com":
          portalId = SCNG_PORTAL_ID;
          break;

        case "testingyouguys.com":
        case "www.testingyouguys.com":
        case "sandiego-obituaries.com":
        case "www.sandiego-obituaries.com":
          portalId = SDUT_PORTAL_ID;
          break;

        case "norcalnewsobits.com":
        case "www.norcalnewsobits.com":
          portalId = NORCAL_PORTAL_ID;
          break;

        default:
          portalId = BANG_PORTAL_ID;
          break;
      }
    }

    if (!portalId) {
      throw new Error("portalId was not assigned");
    }

    if (!portalCookie || override) {
      // setting it to different attribute names
      // so we can clean up the FE code later
      const portalToSave = {
        id: portalId,
        portalID: portalId,
        portalId: portalId
      };

      cookies.set(namespaceKey("dataPortal"), JSON.stringify(portalToSave), {
        maxAge: 60 * 60 * 24 * 365, // 1 year
        path: "/",
        httpOnly: false
      });

      savedCookie = true;
    }
  } catch (e) {
    // await logger.error(`getPortalFromRequest error: ${e.message}`, {
    //   url,
    //   hostname,
    //   portalId,
    //   portalCookie,
    //   req: ctx?.req,
    // });

    dataSource = "ERROR";
    portalId = BANG_PORTAL_ID;
  }

  // await logger.log(`getPortalFromRequest: ${hostname} ${url}`, {
  //   url,
  //   hostname,
  //   portalId,
  //   portalCookie,
  //   urlParts,
  //   dataSource,
  //   query,
  //   savedCookie,
  // });

  return {
    id: portalId
  };
}
