import PropTypes from "prop-types";
import styles from "../../../scss/components/general/ListText.module.scss";

const ListText = ({ selectedItem }) => (
  <div className={`${styles.container} ListText`}>
    {selectedItem?.item && selectedItem.item.map((el, index) => <span key={[el, index].join("_")}>{el}</span>)}
  </div>
);

export default ListText;

ListText.defaultProps = {
  selectedItem: {}
};

ListText.propTypes = {
  selectedItem: PropTypes.shape({
    item: PropTypes.arrayOf(PropTypes.string)
  })
};
