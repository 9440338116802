import getConfig from "next/config";

const NextI18Next = require("next-i18next").default;

const { publicRuntimeConfig = {} } = getConfig() || {};
const { localeSubpaths } = publicRuntimeConfig;
const path = require("path");

module.exports = new NextI18Next({
  defaultLanguage: "en",
  otherLanguages: [],
  localeSubpaths,
  localePath: path.resolve("./public/static/locales"),
});
