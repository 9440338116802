import { clearingObject } from "./storageWorks";

const createTemplateUserBody = (orderId, filledTemplateData, userTemplateID, templateId, adData, orderData) => {
  const body = {
    templateId,
    portalId: filledTemplateData?.portalId,
    name: filledTemplateData?.name,
    // description: filledTemplateData?.description,
    // isActive: filledTemplateData?.isActive,
    // backgroundImage: filledTemplateData?.backgroundImage,
    // type: filledTemplateData?.type,
    // title: filledTemplateData?.title,
    orderId: orderId || "",
    width: filledTemplateData?.width,
    height: filledTemplateData?.height !== null ? filledTemplateData?.height : 0,
    elements: adData || [],

    facebookAd: orderData?.facebookAd
    // specialFields: filledTemplateData?.specialFields,
    // middleSpecialFields: filledTemplateData?.middleSpecialFields,
  };

  if (orderData.printAdDocuments.pdf) {
    body.pdfUrl = orderData.printAdDocuments.pdf;
  }

  if (orderData.printAdDocuments.jpg) {
    body.imgUrl = [orderData.printAdDocuments.jpg];
  }

  return body;
};

const createTemplatesBody = (orderData) =>
  clearingObject({
    digitalTemplateId: orderData?.facebook?.id,
    facebookTemplateId: orderData?.digitalAd?.ID,
    printTemplateId: orderData?.printAd?.id
  });

const bodyOrder = (idOrder, dataPortal, orderData, transactionId) => {
  return clearingObject({
    id: idOrder || "",
    transactionId,
    packageId: orderData.packageId,
    templates: createTemplatesBody(orderData),
    portalId: dataPortal.portalID,
    orderNotes: orderData.orderNotes,
    facebookAd: orderData.facebookAd
  });
};
export { createTemplateUserBody, bodyOrder };
