import styles from "../../../scss/components/general/Loader.module.scss";

const Loader = () => {
  const countDot = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <section>
      <div className={styles.skFadingCircle}>
        {countDot.map((item, index) => (
          <div key={[item, index].join("_")} className={`${styles.skCircle} ${styles[`skCircle_${item}`]}`} />
        ))}
      </div>
    </section>
  );
};

export default Loader;
