import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import useMasterOrder from "redux/hooks/useMasterOrder";
import { validatePublishingDate } from "hooks/usePublicationDatePicker";
import useDataPortal from "hooks/useDataPortal";
import useOrderUpdates from "hooks/useOrderUpdates";

import requests from "../graphql/serverRequest";

/**
 * Checks if all the deadlines in a master order are valid.
 *
 * This is used in the checkout page to determine if the
 * submit/payment/refund buttons should be enabled.
 *
 * @return {boolean} True if all the deadlines are valid, false otherwise.
 * @throws {Error} If the master order is not available or if a publication is not found.
 */
export function useDeadlinesAreValid() {
  const { masterOrder } = useMasterOrder();
  const { orderUpdates } = useOrderUpdates();

  const pubIds = usePubIds();
  const schedules = useSchedules(pubIds);

  // Create a state variable to keep track of whether the deadlines are valid
  const [deadlinesAreValid, setDeadlinesAreValid] = useState(true);

  useEffect(() => {
    if (!pubIds || !schedules) return;

    // Define the validation function
    const validateDeadlines = () => {
      const validity = pubIds.every((pubId) => {
        const pub = getPublicationById(masterOrder?.selectedPublications, pubId);

        if (!pub) {
          return false;
        }

        let schedule = masterOrder?.publicationOrderData?.[pubId]?.schedule || [];

        if (masterOrder?.editingExistingOrder) {
          schedule = orderUpdates?.newPricing?.[pubId]?.datesAffected || [];
        }

        const pubDeadlines = schedules?.[pubId] || {};

        return schedule.every((date) => isValidDate(pubDeadlines, date));
      });

      // Update the state variable
      setDeadlinesAreValid(validity);
    };

    // Call the validation function immediately
    validateDeadlines();

    // Set up a timer to call the validation function every minute
    // const intervalId = setInterval(validateDeadlines, 1000);

    // Clean up the timer when the component unmounts
    // return () => clearInterval(intervalId);
  }, [pubIds, masterOrder, schedules]);

  if (masterOrder?.editingExistingOrder) return true;

  // Return the state variable
  return deadlinesAreValid;
}

/**
 * Checks if master order has at least one valid deadline.
 * This is used in the order detail modal to determine
 * if the Edit Order button and Cancel Order button should be displayed.
 *
 * @return {boolean} True if all the deadlines are valid, false otherwise.
 * @throws {Error} If the master order is not available or if a publication is not found.
 */
export function useHasValidDeadline() {
  const { masterOrder } = useMasterOrder();

  const pubIds = usePubIds();
  const schedules = useSchedules(pubIds);

  // Create a state variable to keep track of whether the deadlines are valid
  const [deadlinesAreValid, setDeadlinesAreValid] = useState(false);

  useEffect(() => {
    if (!pubIds || !schedules) return;

    // Define the validation function
    const validateDeadlines = () => {
      const validity = pubIds.some((pubId) => {
        const pub = getPublicationById(masterOrder?.selectedPublications, pubId);

        if (!pub) {
          return false;
        }

        const schedule = masterOrder?.publicationOrderData[pubId]?.schedule || [];
        const pubDeadlines = schedules?.[pubId] || {};

        return schedule.some((date) => isValidDate(pubDeadlines, date));
      });

      // Update the state variable
      setDeadlinesAreValid(validity);
    };

    // Call the validation function immediately
    validateDeadlines();

    // Set up a timer to call the validation function every minute
    // const intervalId = setInterval(validateDeadlines, 1000);

    // Clean up the timer when the component unmounts
    // return () => clearInterval(intervalId);
  }, [pubIds, masterOrder, schedules]);

  // Return the state variable
  return deadlinesAreValid;
}

/**
 * Returns a publication based on its id.
 * @param {Array} publications - The array of publications.
 * @param {string} pubId - The id of the publication.
 * @return {Object} The publication object.
 */
function getPublicationById(publications = [], pubId) {
  return publications.find((pub) => pub?.id === pubId);
}

/**
 * Validates if a date is valid based on a set of deadlines.
 * @param {Object} pubDeadlines - The deadlines object.
 * @param {string} date - The date to validate.
 * @return {boolean} True if the date is valid, false otherwise.
 */
function isValidDate(pubDeadlines, date) {
  const dateObject = new Date(date);
  const formattedDate = {
    year: dateObject.getFullYear(),
    month: dateObject.getMonth(),
    day: dateObject.getDate()
  };

  const result = validatePublishingDate(pubDeadlines, formattedDate);

  //   console.log(formattedDate, result);

  return result;
}

function usePubIds() {
  const { masterOrder } = useMasterOrder();
  const [pubIds, setPubIds] = useState(null);

  useEffect(() => {
    if (!masterOrder?.publicationOrderData) return;

    setPubIds(Object.keys(masterOrder?.publicationOrderData || {}));
  }, [masterOrder]);

  return pubIds;
}

export function useSchedules(pubIds) {
  const [schedules, setSchedules] = useState(null);

  const [getPublication] = useLazyQuery(requests.GetSchedule, {});

  const dataPortal = useDataPortal();

  useEffect(() => {
    if (!pubIds) return;
    if (!dataPortal?.id) return;

    const schedulePromises = pubIds.map(async (pubId) => {
      if (!pubId) return Promise.resolve({ pubId, schedule: {} });

      const { data } = await getPublication({
        variables: {
          input: {
            id: pubId,
            portalId: dataPortal.id
          }
        }
      });

      return {
        pubId,
        schedule: data?.GetPublication?.standardSchedule || data?.GetPublication?.specialSchedule || {}
      };
    });

    Promise.all(schedulePromises).then((schedulesArray) => {
      const schedulesObject = schedulesArray.reduce((acc, { pubId, schedule }) => {
        acc[pubId] = schedule;
        return acc;
      }, {});

      setSchedules(schedulesObject);
    });
  }, [pubIds, dataPortal]);

  return schedules;
}
