import { useMutation } from "@apollo/client";
import { setToLocalStorage } from "../utils/storageWorks";
import request from "../graphql/serverRequest";
import constants from "../constants/storageWorks";
import { createTemplateUserBody, bodyOrder } from "../utils/orderWorker";
import cookiesWorker from "../utils/cookiesWorker";

import logger from "utils/loggerFrontEnd";

// This is an old function
// I do not know if it is used
// It will be replaced with the new useSaveDraft hook

const useSaveOrder = () => {
  const { getCookie } = cookiesWorker();

  const [UpdateUserTemplate] = useMutation(request.UpdateUserTemplate);

  const [UpdateOrder] = useMutation(request.UpdateOrder);

  const saveOrder = async (body, orderId, idUserTemplate) => {
    await logger.info(`saveOrder() from useSaveOrder.js`, {
      orderId
    });

    const cookies = getCookie("dataPortal");
    let dataPortal;
    if (cookies) {
      dataPortal = JSON.parse(cookies);
    }

    const bodyInput = bodyOrder(orderId, { adData: body?.elements || [], ...dataPortal });
    /* if (body?.templates) {
      bodyInput.templates = body.templates;
    } */
    await UpdateOrder({
      variables: { input: bodyInput },
      awaitRefetchQueries: true,
      update: async (cache, { data: order }) => {
        cache.evict({ fieldName: "Orders" });
        setToLocalStorage(constants.order, order.UpdateOrder);
        if (body?.templateId) {
          const bodyTemplateUser = createTemplateUserBody(order.UpdateOrder.id, body, idUserTemplate);
          if (bodyInput.portalId) {
            bodyTemplateUser.portalId = bodyInput.portalId;
          }
          if (bodyInput.packageId) {
            bodyTemplateUser.packageId = bodyInput.packageId;
          }

          await UpdateUserTemplate({
            variables: {
              input: bodyTemplateUser
            },
            update(cache, { data: template }) {
              setToLocalStorage(constants.templateUserStorage, template.UpdateUserTemplate);
            },
            refetchQueries: [
              {
                query: request.GetOrderPriceReview,
                variables: {
                  input: {
                    portalId: dataPortal.portalID,
                    id: order.UpdateOrder.id
                  }
                }
              }
            ]
          });
        }
      }
    });
  };

  return {
    saveOrder
  };
};

export default useSaveOrder;
