import { useEffect, useState } from "react";

export const useDateSelector = (intialSelectedDates) => {
  const [selectedDays, updateSelectedDays] = useState(intialSelectedDates);

  useEffect(function removeUnpublishableDates() {
    const nextSelectedDays = selectedDays.filter((date) => {
      const currentDate = new Date(date);
      return currentDate > new Date();
    });
    updateSelectedDays(nextSelectedDays);
  }, []);

  function setSelectedDays(newValue) {
    updateSelectedDays((selectedDays) => {
      const prevSelectedDays = [...selectedDays];
      const index = prevSelectedDays.findIndex((date) => {
        let currentDate = new Date(date);
        let newDate = new Date(newValue);
        return currentDate.toUTCString() === newDate.toUTCString();
      });
      if (index === -1) {
        return sortDates([...prevSelectedDays, newValue]);
      }

      prevSelectedDays.splice(index, 1);
      return sortDates(prevSelectedDays);
    });
  }

  function sortDates(dates) {
    return dates.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
  }

  return {
    selectedDays,
    updateSelectedDays,
    setSelectedDays
  };
};
