import { useLazyQuery } from "@apollo/client";

import request from "../graphql/serverRequest";

const useFetchPublication = () => {
  const [fetch] = useLazyQuery(request.Publication, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache"
  });

  return function ({ portalId, publicationId }) {
    let resolve = null;
    let reject = null;

    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    fetch({
      variables: {
        input: {
          portalId,
          id: publicationId
        }
      },
      onCompleted(data) {
        resolve(data?.GetPublication);
      },
      onError: (error) => {
        resolve(null);
      }
    });

    return promise;
  };
};

export default useFetchPublication;
