/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useContext, useState } from "react";
import { cloneDeep, get } from "lodash";

import { AdBuilderContext } from "../adBuilderContext";
import { OrderDataContext } from "../orderContext";

import useProfile from "hooks/useProfile";
import usePriceInspector from "hooks/usePriceInspector";
import useMasterOrder from "src/redux/hooks/useMasterOrder";

import handleFacebookAdPricing from "./handleFacebookAdPricing";
import calculateDuration from "./calculateDuration";
import getPackagePricing from "./getPackagePricing";
import calculateAddOnPricing from "./calculateAddOnPricing";
import calculatePricingAdjustments from "./calculatePricingAdjustments";
import calculateTemplatePricing from "./calculateTemplatePricing";
import calculateImagePricing from "./calculateImagePricing";
import useCheckScheduleChange from "./useCheckScheduleChange";

import { OrderUpdatesContext } from "../orderUpdates";

import { getScheduleDaysFromPublicationDays } from "hooks/usePublicationDatePicker";
import { isPublishingDayAllowed } from "utils/isPublishingDayAllowed";

import { useSchedules } from "hooks/useValidateDeadlines";

const emptyArray = [];

export default function useUpdatePricingTotal({ setTotal, promoCoupon }) {
  // Returns number of days in all schedules
  const checkScheduleChange = useCheckScheduleChange();

  const { masterOrder, updateMasterOrder } = useMasterOrder();

  const orderContext = useContext(OrderDataContext);
  const { orderData } = orderContext;

  const { profile } = useProfile();
  const { canInspectPricing, generatePricingInspector } = usePriceInspector();

  const { adTextHeights, adData } = useContext(AdBuilderContext);

  const { setOrderUpdates } = useContext(OrderUpdatesContext);

  const publicationIds = usePublicationIds();

  const schedules = useSchedules(publicationIds);

  // Calculate the pricing total
  useEffect(() => {
    try {
      const packageId = orderData?.packageId;
      const templateId = orderData?.printAd?.id;

      if (!orderData || !orderData?.printAd?.id || !packageId) {
        return setTotal(null);
      }

      const publicationOrderData = masterOrder?.publicationOrderData ? cloneDeep(masterOrder.publicationOrderData) : {};
      const numPubs = Object.keys(publicationOrderData).length;

      const orderUpdate = {
        previousPricing: masterOrder?.originalPricing,
        newPricing: {}
      };

      const facebookPricing = handleFacebookAdPricing(masterOrder);

      let publicationsPricingTotal = (masterOrder?.selectedPublications || []).reduce((total2, publication) => {
        // Verify the data structure exists
        if (!publicationOrderData[publication.id]) publicationOrderData[publication.id] = {};

        // Calculate the duration
        const duration = calculateDuration(publicationOrderData[publication.id]?.schedule);

        const schedule = publicationOrderData[publication.id]?.schedule;

        // Get the pricing adjustments and template pricing
        const { pricingAdjustments, templatePricing } = getPackagePricing({
          publication,
          packageId,
          templateId,
          selectedPackage: orderData?.selectedPackage,
          numPubs
        });

        //calculate the add on Pricing
        const addOnPricing = calculateAddOnPricing({ publication: publicationOrderData[publication.id] });

        // Calculate the template pricing
        // This does not include image pricing
        const templatePricingTotal = calculateTemplatePricing({
          templatePricing,
          items: adData,
          itemHeights: adTextHeights
        });

        // Calculate the pricing adjustments
        const pricingAdjustmentTotals = calculatePricingAdjustments({
          pricingAdjustments,
          duration,
          templateId,
          adData,
          adTextHeights,
          templatePricingTotal
        });

        // Optionally generate the pricing inspector data
        if (canInspectPricing()) {
          generatePricingInspector({
            pricingAdjustments,
            templatePricing,
            adTextHeights,
            adData,
            publication,
            duration,
            pricingAdjustmentTotals,
            templatePricingTotal
          });
        }

        // Calculate the image pricing
        const imagePricingTotal = calculateImagePricing({
          templatePricing,
          items: adData
        });

        const dailyPricing = [];

        const updatedDates = [];

        const publicationScheduleDays = schedules?.[publication.id] || {};

        for (let i = 0; i < schedule.length; i++) {
          let day = schedule[i];

          let pricingForThisDay = 0;

          const validDay = validatePublishingDate(publicationScheduleDays, new Date(day));

          if (!validDay) {
            pricingForThisDay = masterOrder?.originalPricing?.[publication.id]?.pricing?.templatePricingTotal || 0;
            dailyPricing.push(pricingForThisDay);
          }

          if (validDay) {
            pricingForThisDay = templatePricingTotal;
            updatedDates.push(day);
            dailyPricing.push(pricingForThisDay);
          }
        }

        // calculate pricing from the daily pricing array
        // every 3rd day is free
        const durationPricing = dailyPricing.reduce((total, current, index) => {
          // Consider the first day as index 1, not 0
          if ((index + 1) % 3 === 0) return total;

          return total + current;
        }, 0);

        const pricingItems = {
          durationPricing,
          pricingAdjustmentTotals,
          imagePricingTotal,
          addOnPricing
        };

        const amount = Object.values(pricingItems).reduce((total, current) => total + current, 0);

        orderUpdate.newPricing[publication.id] = {
          ...publicationOrderData[publication.id],
          datesAffected: [...updatedDates],
          schedule
        };

        // Set the pricing data on the publicationOrderData object
        publicationOrderData[publication.id].pricing = {
          pricingAdjustments,
          templatePricing,
          pricingAdjustmentTotals,
          templatePricingTotal,
          imagePricingTotal,
          duration,
          addOnPricing,
          amount
        };

        // Return the total for this publication
        // return total2 + templatePricingTotal * duration + pricingAdjustmentTotals + imagePricingTotal + addOnPricing;
        return total2 + amount;
      }, 0);

      // We need to add the facebook pricing to the first publication
      // I'm not sure if this is the best way to do this, but it works
      // for now.
      // TODO: Refactor this so I don't feel like I'm doing
      // something hacky.
      const firstPubId = masterOrder?.selectedPublications?.[0]?.id;

      // We should always have a firstPubId, but just in case
      if (firstPubId) {
        publicationOrderData[firstPubId].pricing.amount += facebookPricing;
      }

      // now we need to iterate through all publications
      // in publicationOrderData and find the Facebook pricing adjustments
      // for each one, update the value to facebookPricing
      // This will account for the 3/2 deal

      Object.keys(publicationOrderData).forEach((pubId) => {
        if (pubId !== firstPubId) {
          // if this is not the first publication, we need to remove
          // the facebook pricing adjustment
          try {
            publicationOrderData[pubId].pricing.pricingAdjustments = publicationOrderData[
              pubId
            ].pricing.pricingAdjustments.filter((adjustment) => adjustment.name.toLowerCase() !== "facebook");
          } catch (error) {}
          return;
        }

        // if this is the first publication, we need to update the
        // facebook pricing adjustment
        publicationOrderData[pubId].pricing.pricingAdjustments = publicationOrderData[
          pubId
        ].pricing.pricingAdjustments.map((adjustment) => {
          if (adjustment.name.toLowerCase() !== "facebook") {
            return adjustment;
          } else {
            return {
              ...adjustment,
              value: facebookPricing
            };
          }
        });
      });

      const grandTotal = publicationsPricingTotal + facebookPricing;

      updateMasterOrder({
        publicationOrderData,
        grandTotal: grandTotal
      });

      setOrderUpdates(orderUpdate);

      return setTotal(grandTotal);
    } catch (error) {}
  }, [
    adTextHeights,
    promoCoupon,
    adData,
    get(masterOrder, "selectedPublications", emptyArray),
    checkScheduleChange(),
    profile,
    JSON.stringify(get(masterOrder, "publicationOrderData", {})),
    JSON.stringify(get(masterOrder, "originalPricing", {})),
    schedules
  ]);
}

export function getPublicationSchedule(publications, id) {
  const publication = publications.filter((pub) => pub.id === id)[0];
  return publication?.specialSchedule ? publication?.specialSchedule : publication?.standardSchedule || [];
}

export function validatePublishingDate(scheduleDays, dateInQuestion) {
  const publicationDays = getScheduleDaysFromPublicationDays(scheduleDays);

  return isPublishingDayAllowed(publicationDays, {
    year: dateInQuestion.getFullYear(),
    month: dateInQuestion.getMonth(),
    day: dateInQuestion.getDate()
  });
}

function usePublicationIds() {
  const [pubIds, setPubIds] = useState([]);

  const { masterOrder } = useMasterOrder();

  useEffect(() => {
    const pubIds = masterOrder?.selectedPublications?.map((pub) => pub?.id).filter((id) => id);

    setPubIds(pubIds);
  }, [masterOrder?.selectedPublications]);

  return pubIds;
}
