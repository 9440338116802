import PropTypes from "prop-types";

import styles from "../../../scss/components/general/Dot.module.scss";

const Dot = ({ typeDot }) => <div className={`${styles.dot} ${typeDot && styles[typeDot]}`} />;

Dot.defaultProps = {
  typeDot: ""
};

Dot.propTypes = {
  typeDot: PropTypes.string
};
export default Dot;
