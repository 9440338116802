import { useContext } from "react";
import { AppContext } from "../context";

export const useOverlay = () => {
  const { setOverlay, setOverlayTitle, clearOverlay } = useContext(AppContext);

  return { setOverlay, setOverlayTitle, clearOverlay };
};

export default useOverlay;
