export default function calculateAddOnPricing({ publication = {} }) {
  let total = 0;

  const addOns = publication.addOns ? Object.keys(publication.addOns) : [];
  //iterate addOns
  addOns.forEach((addOnId) => {
    total += Number(publication.addOns[addOnId].meta.value) || 0;
  });

  return total;
}
