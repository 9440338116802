import Cookies from "js-cookie";

const getDomainName = () => {
  try {
    const hostname = window.location.hostname;
    const domainParts = hostname.split(".").filter((part) => part !== "www" && part !== "com");
    return domainParts.join("_");
  } catch (e) {
    return "";
  }
};

// const namespaceKey = (key) => `${key}_${getDomainName()}_${process.env.NEXT_PUBLIC_cacheKey}`;

const namespaceKey = (key) => `${key}_${process.env.NEXT_PUBLIC_cacheKey}`;

export const removeCookie = (nameField) => {
  Cookies.remove(namespaceKey(nameField));
};

export const getCookie = (nameField) => Cookies.get(namespaceKey(nameField));

const cookiesWorker = () => {
  const saveCookie = (nameField, data, expiresTime) => {
    if (expiresTime !== undefined) {
      Cookies.set(namespaceKey(nameField), data, { expires: expiresTime });
    } else {
      Cookies.set(namespaceKey(nameField), data);
    }
  };

  const savePortal = (data, nameField) => {
    const cookies = getCookie("dataPortal");

    let dataPortal = {};

    if (cookies) {
      dataPortal = JSON.parse(cookies);

      if (data !== undefined && nameField !== undefined) {
        dataPortal[nameField] = data;
      }
    } else {
      if (data !== undefined && nameField !== undefined) {
        dataPortal[nameField] = data;
      }
    }

    Cookies.set(namespaceKey("dataPortal"), JSON.stringify(dataPortal));
  };
  return { savePortal, saveCookie, getCookie, removeCookie };
};

export default cookiesWorker;
