import { useEffect } from "react";

import { setToLocalStorage, getFromLocalStorage, removeLocalStorage } from "utils/storageWorks";

const KEY = "_order_updates";

export function useSyncOrderUpdatesWithLocalStorage() {
  function get() {
    return getFromLocalStorage(KEY);
  }

  function set(updates) {
    setToLocalStorage(KEY, updates);
  }

  function clear() {
    removeLocalStorage(KEY);
  }

  useEffect(() => {
    get() && set(get());
  }, []);

  return {
    get,
    set,
    clear
  };
}
