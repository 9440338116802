import { useRef, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";

import request from "../graphql/serverRequest";

const useFetchDraftOrder = () => {
  const promise = useRef();

  const [fetch] = useLazyQuery(request.LoadDraftOrder, {
    notifyOnNetworkStatusChange: true,
    cachePolicy: "no-cache",
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted(data) {
      promise.current.resolve(data?.LoadDraftOrder);
    },
    onError: (error) => {
      promise.current.reject(error);
    }
  });

  return useCallback(({ orderId, portalId, userId }) => {
    promise.current = {};
    promise.current.promise = new Promise((resolve, reject) => {
      promise.current.resolve = resolve;
      promise.current.reject = reject;
    });

    fetch({
      variables: { orderId, portalId, userId }
    });

    return promise.current.promise;
  }, []);
};

export default useFetchDraftOrder;
