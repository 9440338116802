const HeaderDashboardListMenu = [
  {
    key: "backToHome",
    name: "back to home",
    url: "/"
  },
  {
    key: "adOrders",
    name: "ad orders",
    url: "/ad-orders"
  },
  {
    key: "account",
    name: "account",
    url: "/account"
  }
];

export default HeaderDashboardListMenu;
