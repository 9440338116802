import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";
import HeaderDashboardListMenu from "./HeaderDashboardListMenu";
import Dot from "../UI/Dot";
import PrismLink from "../general/PrismLink";
import styles from "../../../scss/components/headerDashboard/HeaderDashboardMobileMenu.module.scss";

const HeaderDashboardMobileMenu = ({ t, router, dashboard }) => (
  <div className={styles.container}>
    {HeaderDashboardListMenu.map((item, index) => (
      <div key={[item.name, index].join("_")} className={styles.linkMenu}>
        {router === item.url && <Dot />}
        <PrismLink href={`${item.url}`} asPath={`${item.url}`} typeStyle="linkDashboardMobile" dashboard={dashboard}>
          {t(`${item.name}`)}
        </PrismLink>
      </div>
    ))}
  </div>
);

HeaderDashboardMobileMenu.defaultProps = {
  router: "",
  dashboard: false
};
HeaderDashboardMobileMenu.propTypes = {
  t: PropTypes.func.isRequired,
  router: PropTypes.string,
  dashboard: PropTypes.bool
};

export default withTranslation("indexPage")(HeaderDashboardMobileMenu);
