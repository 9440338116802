export default function getPackagePricing({ publication, templateId, selectedPackage, numPubs = 1 }) {
  try {
    const packageId = selectedPackage?.id;

    const publicationPackage = publication?.packages?.find((p) => p.id === packageId);

    const { pricing } = publicationPackage;

    const { pricingAdjustments, templatePricing, multiplePublicationTemplatePricing } = pricing;

    if (numPubs > 1) {
      const thisTemplatePricing = multiplePublicationTemplatePricing[templateId] || {};
      const pricingKeys = Object.keys(thisTemplatePricing);

      const calculatedPricing = pricingKeys.reduce((acc, key) => {
        const price = thisTemplatePricing[key];

        acc[key] = price * 0.75;

        // make integer
        acc[key] = Math.round(acc[key]);

        return acc;
      }, {});

      return {
        pricingAdjustments,
        templatePricing: calculatedPricing
      };
    }

    const currentTemplatePricing = templatePricing[templateId] || [];

    return { pricingAdjustments, templatePricing: currentTemplatePricing };
  } catch (e) {
    console.log(e.message);
    return { pricingAdjustments: [], templatePricing: {} };
  }
}
