import { addPublication, setPublications, splicePublications } from "../selectedPublications";
import { updateOrder } from "../masterOrderSlice";

//a map of the keys local storage and the redux store
const KEY_MAP = {
  publications: {
    localStorageKey: "publications",
    storeKey: "selectedPublications"
  },
  masterOrder: {
    localStorageKey: "orderMasterData",
    storeKey: "order"
  }
};
//a map of dispatch events and their keys
export const KEYS_BY_DISPATCH_EVENTS = {
  [addPublication.type]: KEY_MAP.publications,
  [setPublications.type]: KEY_MAP.publications,
  [splicePublications.type]: KEY_MAP.publications,
  [updateOrder]: KEY_MAP.masterOrder
};
