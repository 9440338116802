import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styles from "../../../scss/components/general/SpinnerOverlay.module.scss";

const SpinnerOverlay = ({ show }) =>
  show
    ? ReactDOM.createPortal(
        <>
          <div className={styles["spinner-overlay"]}>
            <div className={styles["spinner-container"]}>
              <FontAwesomeIcon icon={faSpinner} size="5x" className="fa-spin" />
            </div>
          </div>
        </>,
        document.body
      )
    : null;

export default SpinnerOverlay;
