import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {}
};

export const masterOrderSlice = createSlice({
  name: "masterOrder",
  initialState,
  reducers: {
    updateOrder(state, { payload }) {
      state.value = { ...state.value, ...payload };
    },
    clearOrder(state) {
      state.value = {};
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateOrder, clearOrder } = masterOrderSlice.actions;

export const masterOrderSelector = (state) => state.order.value;

export default masterOrderSlice.reducer;
