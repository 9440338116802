const listroutes = [
  {
    path: "/",
    title: "home",
    protect: false,
    headerMain: true,
    headerDashboard: false,
    headerLeft: false,
    authRequired: false
  },
  {
    path: "/deadlines",
    title: "deadlines",
    protect: false,
    headerMain: true,
    headerDashboard: false,
    headerLeft: false,
    authRequired: false
  },
  {
    path: "/faqs",
    title: "faqs",
    protect: false,
    headerMain: true,
    headerDashboard: false,
    headerLeft: false,
    authRequired: false
  },
  {
    path: "/how-to-write-an-obituary",
    title: "How to write an Obituary",
    protect: false,
    headerMain: true,
    headerDashboard: false,
    headerLeft: false,
    authRequired: false
  },
  {
    path: "/contact",
    title: "contact",
    protect: false,
    headerMain: true,
    headerDashboard: false,
    headerLeft: false,
    authRequired: false
  },
  {
    path: "/pricing",
    title: "pricing",
    protect: false,
    headerMain: true,
    headerDashboard: false,
    headerLeft: false,
    authRequired: false
  },
  {
    path: "/login",
    title: "login",
    protect: false,
    headerMain: true,
    headerDashboard: false,
    headerLeft: false,
    authRequired: false
  },
  {
    path: "/reset-password",
    title: "Reset Password",
    protect: false,
    headerMain: true,
    headerDashboard: false,
    headerLeft: false,
    authRequired: false
  },
  {
    path: "/create-account",
    title: "create account",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: false
  },
  {
    path: "/account",
    title: "account",
    protect: true,
    headerMain: false,
    headerDashboard: true,
    headerLeft: false,
    authRequired: true
  },
  {
    path: "/cancel-order",
    title: "Cancel Order",
    protect: true,
    headerMain: false,
    headerDashboard: true,
    headerLeft: false,
    authRequired: true
  },
  {
    path: "/ad-orders",
    title: "ad orders",
    protect: true,
    headerMain: false,
    headerDashboard: true,
    headerLeft: false,
    authRequired: true
  },
  {
    path: "/create-publication",
    title: "create publication",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication-alt",
    title: "create publication alt",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/choose-classified-alt",
    title: "choose classified alt",
    protect: true,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication/choose-package",
    title: "choose package",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication/choose-template",
    title: "choose template",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication/build-publication",
    title: "build publication",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication/upgrade-publication",
    title: "upgrade publication",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication/social-media-settings",
    title: "Facebook Ad settings",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication/select-publication-dates",
    title: "Select Publication Date",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication/add-social-media",
    title: "Select Publication Date",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication/upgrade-social",
    title: "upgrade social",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/create-publication/publication-date",
    title: "publication date",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: true,
    authRequired: true
  },
  {
    path: "/review-order",
    title: "review order",
    protect: false,
    headerMain: false,
    headerDashboard: false,
    headerLeft: false,
    authRequired: true
  }
];

export default listroutes;
