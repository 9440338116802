import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";
import HeaderDashboardListMenu from "./HeaderDashboardListMenu";
import styles from "../../../scss/components/headerDashboard/HeaderDashboardMenu.module.scss";
import PrismLink from "../general/PrismLink";

const HeaderDashboardMenu = ({ t, dashboard }) => (
  <div className={styles.container}>
    {HeaderDashboardListMenu.map((item, index) => (
      <PrismLink
        key={[item.key, index].join("_")}
        href={item.url}
        asPath={item.url}
        typeStyle="dashboardMenu"
        dashboard={dashboard}
      >
        {t(`${item.key}`)}
      </PrismLink>
    ))}
  </div>
);

HeaderDashboardMenu.defaultProps = {
  dashboard: false
};
HeaderDashboardMenu.propTypes = {
  t: PropTypes.func.isRequired,
  dashboard: PropTypes.bool
};

export default withTranslation("indexPage")(HeaderDashboardMenu);
