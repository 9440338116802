import { useEffect, useContext } from "react";

import { OrderDataContext } from "context/orderContext";
import { AdBuilderContext } from "context/adBuilderContext";
import { FuneralHomeContext } from "context/funeralHomeContext";

export default function useFuneralData() {
  const { selectedTemplate, setSelectedTemplate } = useContext(OrderDataContext);
  const { adData, setAdData, setImage } = useContext(AdBuilderContext);
  const { funeralHomeData } = useContext(FuneralHomeContext);

  useEffect(() => {
    if (!funeralHomeData || !selectedTemplate?.id) return;

    let funeralHomeImage;

    const newElements = adData.map((element) => {
      if (element.funeralHomeAttr === "logo") {
        const { img } = funeralHomeData;

        const templateWidth = parseFloat(selectedTemplate.width);
        let maxHeight = parseFloat(element.height) || 100;

        // if maxHeight not a number set it to 100
        // 100 is the default height for the logo
        // based on feedback from BANG
        if (isNaN(maxHeight)) maxHeight = 100;

        let x = (templateWidth - img.width) / 2;
        let width = img.width;
        let height = img.height;

        if (width > templateWidth) {
          width = templateWidth;
          height = img.height * (templateWidth / img.width);
          x = 0;

          if (height > maxHeight) {
            height = maxHeight;
            width = img.width * (maxHeight / img.height);

            if (width > templateWidth) {
              width = templateWidth;
            }

            x = (templateWidth - width) / 2;
          }
        }

        if (height > maxHeight) {
          height = maxHeight;
          width = img.width * (maxHeight / img.height);

          if (width > templateWidth) {
            width = templateWidth;
          }

          x = (templateWidth - width) / 2;
        }

        funeralHomeImage = {
          src: funeralHomeData.logo,
          originalSrc: funeralHomeData.logo,
          width,
          height,
          id: element.id
        };

        return {
          ...element,
          // width and height were strings before
          // so I'm leaving them as strings
          width,
          height,
          type: "funeralHomeImage",
          doNotRender: false,
          url: funeralHomeData.logo,
          display: "block",
          x
        };
      } else if (element.funeralHomeAttr) {
        return {
          ...element,
          text: funeralHomeData[element.funeralHomeAttr]
        };
      }

      return element;
    });

    setSelectedTemplate((prevState) => {
      return {
        ...prevState,
        elements: newElements
      };
    });

    setAdData(newElements);

    setImage((oldImage) => {
      return {
        ...oldImage,
        [funeralHomeImage?.id]: {
          src: funeralHomeImage?.src,
          originalSrc: funeralHomeImage?.originalSrc,
          width: funeralHomeImage?.width,
          height: funeralHomeImage?.height
        }
      };
    });
  }, [funeralHomeData, selectedTemplate?.id]);
}
