/* eslint-disable @next/next/no-sync-scripts */
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
import { useReactiveVar } from "@apollo/client";
import Head from "next/head";

import Routes from "../routes/routes";
import listroutes from "../routes/listRoutes";
import urls from "../constants/urls";

import { AppContext, accessToken } from "../context/appContext";
import { OrderDataContext } from "../context";

import useFuneralData from "hooks/useFuneralHome";
import useSaveOrder from "../hooks/useSaveOrder";

import { lastActiveHref } from "../apollo/client";

import logger from "utils/loggerFrontEnd";
import cookiesWorker from "../utils/cookiesWorker";

import Overlay from "components/general/Overlay";
import SpinnerOverlay from "components/general/SpinnerOverlay";
import Footer from "./footer/footer";
import Header from "./header/Header";
import HeaderDashboard from "./headerDashboard/HeaderDashboard";
import styles from "../../scss/layouts/Layout.module.scss";

const Layout = ({ children, portalId }) => {
  const { savePortal } = cookiesWorker();
  const { saveOrder } = useSaveOrder();

  const { spinnerIsActive, title, authStatus, ignored, selectItem, overlayIsActive, overlayTitle } =
    useContext(AppContext);
  const { clearOrderData } = useContext(OrderDataContext);
  const accessTokenVar = useReactiveVar(accessToken);

  useFuneralData();

  const { checkProtect, checkMainHeader, checkLeftHeader, checkDashboard, checkAuthRequired } = Routes();
  const router = useRouter();

  const [renderContent, setRenderContent] = useState(false);

  const styleType = checkMainHeader(listroutes) || checkDashboard(listroutes) ? "" : "headerLeft";

  useEffect(() => {
    if (!checkAuthRequired(listroutes)) return;

    if (accessTokenVar) return;

    router.push("/").then(() => window.scrollTo(0, 0));
  }, [accessTokenVar]);

  useEffect(() => {
    if (checkProtect(listroutes) && !accessTokenVar) {
      setRenderContent(false);
      lastActiveHref(router.asPath);
      router.push("/login");
    } else {
      setRenderContent(true);
    }
  }, [router.asPath, accessTokenVar, ignored]);

  const saveExit = (data, orderId, idUserTemplate) => async () => {
    await logger.info(`Calling saveExit() from Layout.js`, {
      orderId
    });

    if (authStatus) {
      await saveOrder(data, orderId, idUserTemplate);

      clearOrderData();

      router.push("/ad-orders");
    } else {
      router.push("/");
    }
  };

  const newChild = React.cloneElement(children, {
    selectItem,
    savePortal,
    saveExit,
    authStatus,
    portalId
  });

  return (
    <>
      <Head>
        <title>{`Obituary Notices | ${title}`}</title>
      </Head>

      <div className={`${styles.layout} ${styleType && styles[styleType]}`} id="modal">
        <script src={urls.urlPayWay} />
        {renderContent && (
          <>
            {(checkLeftHeader(listroutes) || checkMainHeader(listroutes)) && (
              <Header checkHeader={checkMainHeader(listroutes)} authStatus={authStatus} />
            )}
            {checkDashboard(listroutes) && <HeaderDashboard dashboard={checkDashboard(listroutes)} />}
            <div className={styles.main}>
              <div className={styles.content}>{newChild}</div>
            </div>
            <Footer styleFooter={checkMainHeader(listroutes) || checkDashboard(listroutes)} />
            <SpinnerOverlay show={spinnerIsActive} />
          </>
        )}
      </div>
      {overlayIsActive && <Overlay label={overlayTitle} />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
