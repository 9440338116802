import { useEffect } from "react";

import { setToLocalStorage, removeFromLocalStorage } from "utils/storageWorks";

export default function useSyncLocalStorage({ total, promoCoupon }) {
  useEffect(() => {
    if (total === null) {
      removeFromLocalStorage("_pricing");
    } else {
      setToLocalStorage("_pricing", total);
    }
  }, [total]);

  useEffect(() => {
    if (promoCoupon === null) {
      removeFromLocalStorage("PROMO_COUPON_KEY");
    } else {
      setToLocalStorage("PROMO_COUPON_KEY", promoCoupon);
    }
  }, [promoCoupon]);
}
