/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { createContext, useContext, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";

import logger from "utils/loggerFrontEnd";

import request from "../graphql/serverRequest";
import { AdBuilderContext } from "./adBuilderContext";

import { AppContext } from "./appContext";
import useDataPortal from "hooks/useDataPortal";
import useSpinner from "hooks/useSpinner";
import useOverlay from "hooks/useOverlay";

import useMasterOrder from "redux/hooks/useMasterOrder";
import useFetchPackage from "hooks/useFetchPackage";
import useFetchDraftOrder from "hooks/useFetchDraftOrder";
import useFetchTemplate from "hooks/useFetchTemplate";
import useFetchPublication from "hooks/useFetchPublication";
import useFetchPortal from "hooks/useFetchPortal";
import useFetchPackages from "hooks/useFetchPackages";
import useClearData from "hooks/useClearData";

export const SaveDraftContext = createContext(undefined);

export const SaveDraftProvider = (props) => {
  const router = useRouter();

  const { setSpinner } = useSpinner();
  const { setOverlay, setOverlayTitle, clearOverlay } = useOverlay();
  const clearAllData = useClearData();

  // CONTEXT

  const { adImagePreview, setAdImagePreview, setAdTextHeights, adTextHeights, adData, setAdData } =
    useContext(AdBuilderContext);

  const { profile } = useContext(AppContext);

  // HOOKS

  const dataPortal = useDataPortal();

  const { masterOrder, updateMasterOrder } = useMasterOrder();

  const fetchPackage = useFetchPackage();
  const fetchDraftOrder = useFetchDraftOrder();
  const fetchTemplate = useFetchTemplate();
  const fetchPublication = useFetchPublication();
  const fetchPortal = useFetchPortal();
  const fetchPackages = useFetchPackages();

  // QUERIES AND MUTATIONS

  const [saveDraftMutation] = useMutation(request.SaveDraftOrder, {
    notifyOnNetworkStatusChange: true
  });

  const [adImages] = useMutation(request.GenerateAdImage);

  // FUNCTIONS

  const getSaveDraftPayload = () => {
    const payload = {
      portalId: dataPortal.portalID,
      id: masterOrder?.id,
      adTextHeights,
      adData,
      adImagePreview,
      packageId: masterOrder.packageId,
      templateId: masterOrder.templateId,
      publicationOrderData: masterOrder.publicationOrderData,
      selectedPublications: masterOrder.selectedPublications,
      version: "2",
      userId: profile?.id,
      orderNotes: masterOrder?.orderNotes,
      shippingAddress: masterOrder?.shippingAddress,
      facebookAd: masterOrder?.facebookAd
    };

    if (masterOrder.hasOwnProperty("userId") && masterOrder.userId !== profile?.id) {
      // masterOrder.originalCustomerId = masterOrder.userId;
      payload.originalCustomerId = masterOrder.userId;
      payload.userId = masterOrder.userId;

      logger.debug(`getSaveDraftPayload() --> masterOrder.userId !== profile.id`, {
        orderId: masterOrder.id,
        userId: masterOrder.userId,
        profileId: profile.id
      });
    }

    if (masterOrder?.editingExistingOrder) {
      const newId = uuidv4();
      logger.debug(`getSaveDraftPayload() --> assigning new Order ID`, { orderId: newId });
      payload.id = newId;
    }

    return payload;
  };

  const saveDraft = async () => {
    if (!masterOrder?.id) return;

    setOverlayTitle("Saving to Drafts");
    setOverlay(true);

    const payload = getSaveDraftPayload();

    await logger.info("Saving Draft Order", { orderId: masterOrder.id, payload });

    if (masterOrder.id !== payload.id) {
      await logger.info(`Saving Draft with new ID: ${payload.id}`, { orderId: masterOrder.id });
      await logger.info(`Saving Draft with new ID: ${payload.id}`, { orderId: payload.id });
    } else {
      await logger.info(`Saving Draft with same ID`, { orderId: masterOrder.id });
    }

    try {
      await saveDraftMutation({
        variables: {
          input: {
            ...payload
          }
        }
      });

      router
        .push({
          pathname: "/ad-orders",
          query: { ordersStatus: "DRAFT" }
        })
        .then(async () => clearAllData().then(clearOverlay));
    } catch (e) {
      await logger.error(`Error saving draft order: ${e.message}`, {
        orderId: masterOrder?.id
      });
      clearOverlay();
      alert("There was an error saving your draft order. Please try again.");
    }
  };

  const loadDraft = useCallback(
    async (orderId) => {
      setOverlayTitle("Loading Draft Order");

      await logger.info(`Loading Draft order`, {
        orderId
      });

      const draft = await fetchDraftOrder({
        orderId,
        portalId: dataPortal.portalID,
        userId: profile.id
      })
        .then((o) => {
          return {
            ...o,
            status: "DRAFT"
          };
        })
        .catch(async (e) => {
          await logger.error(`FETCH DRAFT ORDER ERROR: ${e.message}`, {
            orderId,
            error: e.message
          });
          return null;
        });

      if (!draft) {
        clearOverlay();

        alert("Error loading draft order. Please try again.");

        return;
      }

      try {
        if (draft?.version === "2") {
          return await loadV2Order(draft);
        }
        return await loadV1Order(draft);
      } catch (e) {
        await logger.error(`LOAD DRAFT ERROR: ${e.message}`, {
          draft,
          orderId
        });
        clearOverlay();
        alert("Error loading draft order. Please try again.");
      }
    },
    [dataPortal, profile]
  );

  // Loading a V2 order is the same as loading a V2 Draft Order
  // TODO - move this to a separate file
  // It is also being used when editing a V2 order
  const loadV2Order = useCallback(async (draft) => {
    await logger.info(`Loading V2 Order`, {
      orderId: draft.id
    });

    let publicationIds = [];

    if (draft?.publicationOrderData) {
      publicationIds = Object.keys(draft?.publicationOrderData || {});
    } else {
      publicationIds = draft?.selectedPublications?.map((p) => p.id);
    }

    const selectedPublications = [];

    setOverlayTitle("Loading Publications");

    await logger.debug(`Loading Publications`, {
      orderId: draft.id,
      publicationIds
    });

    // I think that the fetch function in the fetchPublcation hook can not be run
    // in parallel, so I am using a for loop instead of a map
    for (let i = 0; i < publicationIds.length; i++) {
      const publicationId = publicationIds?.[i];
      const portalId = draft?.portalId;

      const publication = await fetchPublication({ publicationId, portalId });

      if (publication) {
        selectedPublications.push(publication);
      }
    }

    setOverlayTitle("Loading Order Information");

    await logger.debug(`Loading Order Information`, {
      orderId: draft.id
    });

    const results = await Promise.all([
      fetchPackage({ packageId: draft.packageId, portalId: draft.portalId }),
      fetchTemplate({ templateId: draft.templateId, portalId: draft.portalId }),
      fetchPortal({ portalId: draft.portalId }),
      fetchPackages({ portalId: draft.portalId, publicationId: publicationIds[0] })
    ]);

    const [selectedPackage, selectedTemplate, portal, packages] = results;

    await logger.debug(`Loading Ad Image Preview`, {
      orderId: draft.id
    });

    const adImagePreview = await adImages({
      variables: {
        input: {
          template: {
            ...selectedTemplate,
            elements: draft.adData
          },
          portalId: draft.portalId,
          orderId: draft.id
        }
      }
    }).catch(() => ({}));

    const data = adImagePreview?.data?.GenerateAdImage || {};

    const { pdf, jpg, height, width } = data;

    selectedPackage.pricing = packages.find((p) => p.id === selectedPackage.id).pricing;

    setOverlayTitle("Loading Build Obituary Page");

    setAdTextHeights(draft.adTextHeights);
    setAdData(draft.adData);
    setAdImagePreview(draft.adImagePreview);

    updateMasterOrder(
      {
        portal,
        selectedPublications,
        publicationOrderData: draft.publicationOrderData,
        originalPricing: draft.publicationOrderData,
        packages,
        id: draft.id,
        selectedPackage,
        packageId: selectedPackage.id,
        templateId: selectedTemplate.id,
        templateGroup: selectedPackage.templateGroups[0],
        selectedTemplate,
        packageData: selectedPackage,
        printAd: {
          ...selectedTemplate,
          elements: draft.adData
        },
        pdfHeight: height,
        pdfWidth: width,
        printAdDocuments: {
          pdf,
          jpg
        },
        editingExistingOrder: draft.status !== "DRAFT",
        orderNotes: draft?.orderNotes,
        userId: draft?.userId,
        shippingAddress: draft?.shippingAddress,
        facebookAd: draft?.facebookAd
      },
      true
    );

    await logger.info(`Routing to Build Publication Page`, {
      orderId: draft.id
    });

    router.push("/create-publication/build-publication").then(() => {
      setSpinner(false);
      clearOverlay();
    });
  }, []);

  const loadV1Order = useCallback(async (draft) => {
    await logger.info(`Loading v1 Order`, {
      orderId: draft.id
    });

    setOverlayTitle("Loading Publications");

    const publicationIds = [draft?.currentPublication?.id];

    const publicationPromises = publicationIds.map((pubId) =>
      fetchPublication({ publicationId: pubId, portalId: draft.portalId })
    );

    setOverlayTitle("Loading Order Information");

    const promises = await Promise.all([
      fetchPackage({ packageId: draft?.orderData?.packageId, portalId: draft.portalId }),
      fetchTemplate({ templateId: draft?.orderData?.selectedTemplate?.id, portalId: draft.portalId }),
      Promise.all(publicationPromises),
      fetchPortal({ portalId: draft.portalId }),
      fetchPackages({ portalId: draft.portalId, publicationId: publicationIds[0] })
    ]).catch(async (e) => {
      await logger.error(`Error loading v1 order: ${e.message}`, {
        orderId: draft.id
      });
      alert("Error loading draft order. Please try again.");
      setSpinner(false);
      clearOverlay();
      return [];
    });

    if (!promises.length) return;

    const [selectedPackage, selectedTemplate, selectedPublications, portal, packages] = promises;

    const adImagePreview = await adImages({
      variables: {
        input: {
          template: {
            ...selectedTemplate,
            elements: draft.adData
          },
          portalId: draft.portalId,
          orderId: draft.id
        }
      }
    }).catch(() => ({}));

    const data = adImagePreview?.data?.GenerateAdImage || {};

    const { pdf, jpg, height, width } = data;

    selectedPackage.pricing = packages.find((p) => p.id === selectedPackage.id).pricing;

    setOverlayTitle("Loading Build Obituary Page");

    updateMasterOrder({
      portal,
      selectedPublications,
      id: draft.id,
      packages,
      selectedPackage,
      packageId: selectedPackage.id,
      templateId: selectedTemplate.id,
      templateGroup: selectedPackage.templateGroups[0],
      selectedTemplate,
      packageData: selectedPackage,
      printAd: {
        ...selectedTemplate,
        elements: adData
      },
      pdfHeight: height,
      pdfWidth: width,
      printAdDocuments: {
        pdf,
        jpg
      }
    });

    setAdTextHeights(draft.adTextHeights);
    setAdData(draft.adData);
    setAdImagePreview(draft.adImagePreview);

    await logger.info(`Routing to Build Publication Page`, {
      orderId: draft.id
    });

    router.push("/create-publication/build-publication").then(() => {
      setSpinner(false);
      clearOverlay();
    });
  }, []);

  const providerValue = {
    saveDraft,
    loadDraft,
    getSaveDraftPayload,
    loadV2Order,
    loadV1Order
  };

  return <SaveDraftContext.Provider value={providerValue}>{props.children}</SaveDraftContext.Provider>;
};
