export default function calculateImagePricing({ templatePricing, items }) {
  return items.reduce((runningTotal, element) => {
    if (!element) return runningTotal;

    try {
      const { id, url, type } = element;
      const pricePerLine = templatePricing[id];

      if (!pricePerLine) {
        return runningTotal;
      }

      if (type === "image" && url) {
        return runningTotal + pricePerLine;
      }
    } catch (error) {}

    return runningTotal;
  }, 0);
}
