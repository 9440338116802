export default function calculateTemplatePricing({ templatePricing, items = [], itemHeights }) {
  return items.reduce((runningTotal, element) => {
    if (!element) return runningTotal;

    try {
      const { id, url, type } = element;
      const pricePerLine = templatePricing?.[id];

      if (!pricePerLine) {
        return runningTotal;
      }

      if (type === "image" && url) {
        return runningTotal;
      }

      if (!itemHeights?.[id]) {
        return runningTotal;
      }

      const numberOfLines = itemHeights[id];

      // verify that numberOfLines is a number and not NaN
      if (!numberOfLines || Number.isNaN(numberOfLines)) {
        return runningTotal;
      }

      const itemTotal = pricePerLine * numberOfLines;

      return runningTotal + itemTotal;
    } catch (e) {
      return runningTotal;
    }
  }, 0);
}
