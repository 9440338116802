import { useState, useEffect } from "react";

export default function useFormattedTotal({ total }) {
  const [formattedTotal, setFormattedTotal] = useState(null);

  useEffect(() => {
    if (!total) return;
    const formatted = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(total / 100);
    setFormattedTotal(formatted);
  }, [total]);

  return { formattedTotal, setFormattedTotal };
}
