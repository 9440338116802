import { createContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { setToLocalStorage, removeFromLocalStorage } from "../utils/storageWorks";

import getImage from "utils/getImage";

import useDataPortal from "hooks/useDataPortal";
import useProfile from "hooks/useProfile";

import request from "../graphql/serverRequest";

const FH_KEY = "_funeral_home_data";

const FuneralHomeContext = createContext(undefined);

const FuneralHomeProvider = (props) => {
  const { profile } = useProfile();
  const dataPortal = useDataPortal();

  const [funeralHomeData, _setFuneralHomeData] = useState(null);

  const [getFuneralHome, { error }] = useLazyQuery(request.FuneralHome, {
    notifyOnNetworkStatusChange: true
  });

  // Error handling
  useEffect(() => {
    if (!error) return;
    console.log(`Error loading Funeral Home Data: ${error.message}`);
  }, [error]);

  // Clear State and Local Storage when profile is null
  // Happens when we log out
  useEffect(() => {
    if (!profile) {
      _setFuneralHomeData(null);
      removeFromLocalStorage(FH_KEY);
    }
  }, [profile]);

  // Initialize the API to fetch funeral Home Data
  // and save it to the State and Local Storage
  useEffect(() => {
    if (!profile?.funeralHomeId) return;
    if (!dataPortal?.portalID) return;

    getFuneralHome({
      variables: {
        input: {
          id: profile.funeralHomeId,
          portalId: dataPortal.portalID
        }
      },
      onCompleted: async ({ GetFuneralHome }) => {
        const img = await getImage(GetFuneralHome?.logo).catch((e) => {
          throw new Error(`GetImage called on funeral home logo "${GetFuneralHome?.logo}" failed: ${e.message}`);
        });

        const obj = {
          ...GetFuneralHome,
          img: {
            src: GetFuneralHome?.logo,
            width: img?.width || 0,
            height: img?.height || 0
          }
        };

        _setFuneralHomeData(obj);
        setToLocalStorage(FH_KEY, obj);
      }
    });
  }, [profile, dataPortal]);

  const providerValue = {
    funeralHomeData
  };

  return (
    <FuneralHomeContext.Provider displayName="Funeral Home Context" value={providerValue}>
      {props.children}
    </FuneralHomeContext.Provider>
  );
};

export { FuneralHomeContext, FuneralHomeProvider };
